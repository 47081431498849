function getpathkey (obj, bkey = window.location.pathname) {
    bkey = bkey.replace(new RegExp("^/"), '');
    if (bkey && obj) {
        let objkeys = Object.keyify(obj);
        let keys = bkey.posiblepath("/", "/").reverse();
        let retkey = keys.reduce((ckey, key) => {
            let rkey = ckey;
            let haskey = objkeys.find(k => k.startsWith(`${key}.`));
            if (haskey && !rkey) {
                rkey = Object.getNested(obj, key);
            }
            return rkey;
        }, "");
        return retkey ? retkey : obj;

    } else {
        return obj;
    }
}
export const commonService = {
    getApiPaths() {
        let api = this.api;
        api = getpathkey(api);
        api = {
            ...api,
            ...getpathkey(api)
        };
        return api;
    },
    apiSuccess(resolve = ((v) => (v)), id = 0, responce = {}) {
        app.$gset('loading',false);
        this.$nextTick(()=>{
            resolve(responce.data);
        });
    },
    applogout() {
        return new Promise((resolve, reject) => {
            app.props.store("authResponce", false);
            app.props.store("user", false);
            localStorage.clear();
            resolve();
        });
    },
    apiFailure(reject, id, error) {
        let app = window?.app;
        app.$gset('loading',false);
        this.$nextTick(()=>{
            if ([400,500].includes(error?.response?.status)) {
                let message = (
                    error.response?.data?.issues?.message ||
                    error.response?.data?.issues?.error ||
                    error.response?.data?.issues?.data.error ||
                    error.response?.data?.message ||
                    error.response?.data?.msg
                )
                alert(message);
            } else if (error?.response?.status == 502 || error?.response?.status == 404) {
                let message = (
                    error.response?.data?.issues?.message
                )
                alert(message);
            }
            else if (error?.response?.status == 401) {
                /*
                let refreshTokenAttemp = localStorage.getItem("refreshTokenAttemp");
                refreshTokenAttemp = refreshTokenAttemp || 0;
                if (refreshTokenAttemp < 5) {
                    mixins.debounce(() => {
                        app.props.api.apiRefreshToken()
                            .then(() => {
                                window.location.reload()
                            })
                            .catch(() => app.props.api.applogout());
                    });
                } else {
                    alert("Maximum Session Refresh Attempt Reached");
                    localStorage.setItem("refreshTokenAttemp", 0);
                }
                */
            }
            console.log({ error }, error?.response?.status);
            reject(error);
        });
    },
    endpoint2url(endpoint) {
        let api = this.api;
        return endpoint.startsWith("http") ? endpoint : api.api_base + endpoint;
    },
    filterPayload(obj, inc = []) {
        const filter = (obj, predicate) =>
            Object.keys(obj)
                .filter((key) => predicate(obj[key], key, obj))
                .reduce((res, key) => {
                    res[key] = obj[key];
                    return res;
                }, {});
        return filter(obj, (v, k) => inc.includes(k));
    },
    request(endobj = "", post = {}, header = {}) {
        let endpoint = endobj.url;
        let objmethod = endobj.method;
        let payload = Object.keys(endobj.payload || {}).length ? endobj.payload : post;
        let data = this.filterPayload(post, Object.keys(payload));
        let axios = window.axios;
        let api = this.api;

        let inHeaders = {
            headers: {
                authorization: (this.$state?.authorisation?.token || ""),
                refresh: (this.$state?.authorisation?.refresh || ""),
                "Content-Type": "multipart/form-data",
                ...header
            },
        };
        let bundel = {
            payload: {},
            urltransform: (data = app.mixin.params(), apiendobj = endobj) => {
                let func = apiendobj.url;
                if (func instanceof Function) {
                    endpoint = func(data);
                } else {
                    endpoint = Object.entries(data).reduce((url, [key, val]) => {
                        return url.split(`{${key}}`).join(val);
                    }, apiendobj.url);
                }
                bundel.url = endpoint.startsWith("http") ? endpoint : api.api_base + endpoint;
                return bundel;
            },
            multipart: () => {
                inHeaders.headers = {
                    ...(inHeaders.headers || {}),
                    "Content-Type": "multipart/form-data"
                };
                return bundel;
            },
            config: (reqconfig) => {
                let temp = reqconfig instanceof Function ? reqconfig(inHeaders) : reqconfig;
                inHeaders = {
                    ...inHeaders,
                    ...temp
                }
                return bundel;
            },
            headers: (reqconfig) => {
                let temp = reqconfig instanceof Function ? reqconfig(inHeaders.headers) : reqconfig;
                inHeaders.headers = {
                    ...temp
                }
                return bundel;
            },
            transform(callback = ((v) => (v))) {
                data = callback(post);
                return bundel;
            },
            exec: (message = false, method = "get") => {
                bundel.results = new Promise((resolve, reject) => {
                    let app = window?.app;
                    let id = null;
                    app.$gset('loading',true);
                    let loaded = loader("<i class='fa-solid fa-circle-radiation fa-spin'></i>");
                    if (message) {
                        id = Math.random().toString().slice(2);
                        app.$gset(`loader.${id}`, { message, id, type: "loader" });
                    }
                    method = objmethod || method || (data instanceof FormData ? "post" : "get");
                    let url = this.endpoint2url(endpoint);
                    let isGet = method == "get";
                    let isDelete = method == "delete";
                    url = isGet ? url + Object.encodeuri(data) : url;
                    let requestarr = isGet ? ({ url, inHeaders }) : ({ url, data, inHeaders });
                    requestarr = isDelete ? ({
                        url, inHeaders: {
                            ...inHeaders,
                            data
                        }
                    }) : requestarr;
                    bundel.payload.messageId = id;
                    axios[method](...Object.values(requestarr))
                        .then((...arg) => this.apiSuccess(resolve, id, ...arg))
                        .catch((...arg) => this.apiFailure(reject, id, ...arg))
                        .finally(()=>loaded.close());
                });
                return bundel;
            },
            log() {
                console.log({ endobj, post, data, Headers: inHeaders, responce: bundel.results });
                return bundel;
            },
            mapresults(callback = ((v) => (v))) {
                const execpromise = bundel.results;
                const newpromise = new Promise((resolve, reject) => {
                    execpromise.then(async (data) => {
                        let resultdata = callback(data);
                        if (resultdata instanceof Promise) {
                            resultdata = await resultdata;
                        }
                        resolve(resultdata)
                    }).catch(reject);
                });
                bundel.results = newpromise;
                return bundel;
            },
            get() {
                let app = window?.app;
                const execpromise = bundel.results;
                const newpromise = new Promise((resolve, reject) => {
                    execpromise.then(async (data) => {
                        resolve(data)
                    }).catch((...args) => {
                        console.log("bundel error", ...args);
                        reject(...args);
                    }).then(() => {
                        let loaders = app?.$store?.loader;
                        if (bundel.payload.messageId) {
                            delete loaders[bundel.payload.messageId];
                        }
                        app.$gset(`loader`, loaders);
                    });
                });
                return newpromise;
            }
        };
        return bundel;
    },
    mokeapi(callback = (() => ('')), message = "Getting data please wait...", delay = 1000) {
        return new Promise((resolve, reject) => {
            let app = window?.app;
            let id = Math.random().toString().slice(2);
            message && app.mixin.pushRequest({ message, id });
            setTimeout(() => {
                resolve(callback());
                message && app.mixin.popRequest(id);
            }, delay);
        });
    },
    apiCurd(modelName,options,callback=(v=>v)) {
        return callback(this.request(this.api.CURD, options).urltransform({MODELNAME:modelName})).exec().mapresults(respose=>{
            if(options.action=="list") {
                let params = Object.except(urlparams(),["tablename","modelname"]);
                respose.params = params;
                respose.getVisible = (fields={})=>{
                    let nondefaults = Object.keys(Object.filter(fields,(v)=>!v.hasdefault));
                    return Object.keys(Object.filter(fields,(v)=>!v.hasdefault&&(nondefaults.length<=5||v.required))).sort((a,b)=>(fields[a].index-fields[b].index)).slice(0,5);
                };
                respose.getFields = (fields={})=>{
                    if(respose.types && respose.types instanceof Object && Object.keys(respose.types).length) {
                        return Object.entries(respose.types).reduce((col,[key,value])=>{
                            let field = col[key];
                            if(field) {
                                field.type = field.type || value.type;
                            } else {
                                field = col[key] = {...value,name:key,label: key.toTitleCase() };
                            }
                            if(["select","text","textarea","number"].includes(field.type)) {
                                field.filter = true;
                            }
                            if(field && key=="id") {
                                field.width = 50;
                                field.label = "ID"
                            }
                            if(Object.keys(params).includes(key)) {
                                field.canadd = false;
                                field.defaultValue = params[key];
                            }
                            return col;
                        },fields);
                    } else {
                        return fields;
                    }
                }
            }
            return respose;
        }).get();
    },
    apiGetFile(data) {
        return this.request(this.api.CURD, {
            action:"getFile",
            ...data
        }).urltransform({MODELNAME:"File"}).exec().get();
    },
    apiGetFiles(data) {
        return this.request(this.api.CURD, {
            action:"list",
            ...data
        }).urltransform({MODELNAME:"File"}).exec().get();
    },
    apiFileUpload(data={}) {
        return this.request(this.api.CURD, {
            action:"upload",
            uploaded_in: location.href,
            ...data
        }).urltransform({MODELNAME:"File"}).multipart().exec().get();
    },
    apiFileRemove(data) {
        return this.request(this.api.CURD, {
            action:"remove",
            ...data
        }).urltransform({MODELNAME:"File"}).exec().get();
    }
}

export default commonService;