<template lang="">
    <div v-if="modelValue" class="AppModal pt-16 fixed top-0 left-0 bg-slate-800/80 flex justify-center items-center h-screen w-screen overflow-auto" >
        <div class="modal-content bg-white rounded-md p-4 min-w-max dark:bg-slate-900 flex flex-col relative m-auto">
            <i class='fa fa-close float-right cursor-pointer absolute -top-1 -right-1 dark:bg-slate-600 rounded-full p-2' @click="closeMoadl" ></i>
            <slot></slot>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        modelValue: { type: Boolean, default: false },
    },
    methods: {
        closeMoadl() {
            this.$emit('update:modelValue', false);
        },
    },
    mounted() {
        window.AppModal = this;
    }
}
</script>
<style lang="scss">
    .AppModal {
        max-width: 100vw;
        max-height: 100vh;
        .modal-content {
            min-width: 300px;
            min-height: 300px;
            max-width: 50%;
        }
    }
</style>