<template>
    <div class="AppSchemaResolver flex flex-col gap-2 p-2">
      <div class="flex flex-wrap gap-2" v-for="(inschema,i) in groups" :key="i">
          <template v-if="inschema && isNaN(Object.keys(inschema)[0])">
              <div v-for="(field,key) in Object.sortBySubstr(inschema)" :key="key" :ref="key" :class="{'grow w-full empty:hidden':typeof field!=='string','':typeof field=='string','w-full':field==='code'}" >
                  <template v-if="typeof field=='string'">
                      <!-- <template v-if="modelValue.type !== 'select' && ['refers', 'storekey', 'option_key', 'option_value', 'query_params'].includes(key)"></template>
                      <template v-else-if="modelValue.type === 'select' && modelValue.options?.length && ['refers', 'storekey'].includes(key)"></template>
                      <template v-else-if="key === 'query_params' && !modelValue.refers"></template> -->
                      <AppInput
                          v-if="typeof rules =='object'?rules[key].callFunc(node).run(collect||parentdata,index,key,preindex,parentdata):true"
                          :label="getLabel(attributes[key],key)"
                          :name="key"
                          :type="field"
                          :inputHeight="getInputHeight(attributes[key],key)"
                          :description="getInputDescription(attributes[key],key)"
                          v-bind="getAttributes(attributes[key],key,attributes)"
                          v-model="collect[key]"
                          optionallabel=""
                          :class="{hidden:!canKeyVisble(key,attributes)}"
                      >
                      </AppInput>
                  </template>
                  <template v-else-if="(field instanceof Array )">
                      <!-- <template v-if="key === 'options' && ((modelValue.type !== 'select') || (modelValue.type === 'select' && modelValue.refers))"></template> -->
                      <div class="flex flex-col flex-wrap gap-2" v-if="canArrayVisible(attributes[key][1]) && (rules[key][1]&&rules[key][1].rule?rules[key][1].rule.callFunc(node).run(collect||parentdata,index,key,preindex):true)" >
                          <div>
                              <span>{{(key||'').toTitleCase()}}</span>
                          </div>
                          <template v-if="typeof field[0] == 'object'">
                              <AppDragList :draggable="canDraggable(attributes[key][1])" :list="collect[key]" @change-list="(list)=>{collect[key] = list}">
                                  <template #default="{obj,i}">
                                      <AppAccordion
                                          class="object-array"  
                                          :divided="false"
                                          :clickid="collect[key][i]&&collect[key][i]['lineupId']&&'lineupId'+collect[key][i]['lineupId']"
                                          :hidetype="'absolute'"
                                      >
                                          <template #title>
                                              <span v-if="canDraggable(attributes[key][1])" class="mr-2">
                                                  <i class="fa-solid fa-grip-vertical cursor-move mr-auto w-auto text-indigo-500 w-full h-full"></i>
                                              </span>
                                              <span class="whitespace-nowrap" >
                                                  <span>Item {{i+1}}</span>
                                                  <span v-if="collect[key][i]&&collect[key][i]['name']">
                                                      {{ " | "+collect[key][i]["name"] }}
                                                  </span>
                                              </span>
                                              <i
                                                  v-if="canDelete(attributes[key][1])"
                                                  class="fa-regular fa-trash-can cursor-pointer mr-auto w-auto text-red-500 w-full h-full"
                                                  @click.stop="removeItem(key,i)"></i>
                                              <div class="relative ml-auto mr-2 flex">
                                                  <template v-if="collect[key][i]&&collect[key][i]['publish']" >
                                                      <span class="text-indigo-500">published</span>
                                                  </template>
                                                  <template v-if="canAdd(attributes[key][1])" >
                                                      <button class="mx-2" type="button" @click.stop="cloneItem(collect,key,i,attribute,field)" >Clone</button>
                                                  </template>
                                                  <em class="fa fa-ellipsis-v cursor-pointer px-2" @click.stop="openMoreDrop($event)" ></em>
                                                  <div class="absolute top-full right-0 hidden w-28 rounded-md bg-gray-800 z-10">
                                                      <div class="first:pt-2 pb-2 px-2 flex gap-2" @click.stop="compyItem(collect[key],i)" >
                                                          <i
                                                              class="fa-regular fa-copy cursor-pointer ml-auto w-auto text-white"></i>
                                                          <span class="inline-block grow">Copy</span>
                                                      </div>
                                                      <div class="pb-2 px-2 flex gap-2" @click.stop="pasteItem(collect[key],i)" >
                                                          <i class="fa-regular fa-paste cursor-pointer w-auto text-white"></i>
                                                          <span class="inline-block grow" >Paste</span>
                                                      </div>
                                                  </div>
                                              </div>
                                          </template>
                                          <template #content>
                                              <AppSchemaResolver
                                                  v-model="collect[key][i]"
                                                  :schema="schema[key][0]" 
                                                  :rules="rules[key][0]"
                                                  :attributes="attributes[key][0]"
                                                  :parentdata="collect||parentdata"
                                                  :incollect="collect[key][i]"
                                                  :arrkey="'Item '+(i+1)"
                                                  :index="i"
                                                  :preindex="prei+i"
                                                  class="border p-2"
                                                  >
                                              </AppSchemaResolver>
                                          </template>
                                      </AppAccordion>
                                  </template>
                              </AppDragList>
                          </template>
                          <div v-else class="flex flex-wrap">
                              <div class="grow flex" v-for="(obj,i) in (collect[key]||[])" :key="i">
                                  <AppInput
                                      pclass="grow"
                                      :label="'Item '+(i+1)"
                                      :name="'item-'+(i+1)"
                                      :type="field[0]"
                                      v-bind="getAttributes(attributes[key],key,attributes)"
                                      v-model="collect[key][i]"
                                      optionallabel=""
                                      :class="{hidden:!canKeyVisble(key,attributes)}"
                                  >
                                  </AppInput>
                                  <i
                                      class="fa-regular fa-trash-can cursor-pointer mr-auto w-auto text-red-500 w-full h-full"
                                      @click.stop="removeItem(key,i)"></i>
                              </div>
                              
                          </div>
                          
                          <div class="pr-2 pb-2" v-if="canAdd(attributes[key][1])">
                              <AppButton type="button" @click.stop="addItem(collect,key,attribute,field)" >Add</AppButton>
                          </div>
                      </div>
                  </template>
                  <template v-else-if="field&&(typeof field == 'object')">
                      <template v-if="rules[key].rule?rules[key].rule.callFunc(node).run(collect||parentdata,index,key,preindex):true">
                          <AppAccordion
                              class="grow"
                              :divided="false" 
                              :active="true"
                              :opendefault="false"
                              :active-color="['primary','secondary'].includes(key)?'bg-yellow':'bg-indigo'"
                              :hidetype="'absolute'"
                              >
                              <template #title>
                                  <span>{{(key||'').toTitleCase()}}</span>
                                  <div class="relative ml-auto mr-2">
                                      <em class="fa fa-ellipsis-v cursor-pointer px-2" @click.stop="openMoreDrop($event)" ></em>
                                      <div class="absolute top-full right-0 hidden w-28 rounded-md bg-gray-800 z-10">
                                          <div class="first:pt-2 pb-2 px-2 flex gap-2" @click.stop="compyItem(collect,key)" >
                                              <i class="fa-regular fa-copy cursor-pointer ml-auto w-auto text-white"></i>
                                              <span class="inline-block grow" >Copy</span>
                                          </div>
                                          <div class="pb-2 px-2 flex gap-2" @click.stop="pasteItem(collect,key)" >
                                              <i class="fa-regular fa-paste cursor-pointer w-auto text-white"></i>
                                              <span class="inline-block grow">Paste</span>
                                          </div>
                                      </div>
                                  </div>
                              </template>
                              <template #content>
                                  <AppSchemaResolver 
                                      v-model="collect[key]"
                                      :schema="field"
                                      :rules="rules[key]"
                                      :attributes="attributes[key]"
                                      :parentdata="collect||parentdata"
                                      :incollect="collect[key]"
                                      :index="index"
                                      :preindex="preindex"
                                      >
                                  </AppSchemaResolver>
                              </template>
                          </AppAccordion>
                      </template>
                      
                  </template>
              </div>
          </template>
          <template v-else>
              <div class="flex flex-col flex-wrap gap-2 w-full" >
                  <template v-if="typeof inschema[0] == 'object'">
                      <AppAccordion 
                          v-for="(obj,i) in (collect||[])" 
                          :key="i" 
                          :divided="false" 
                          :hidetype="'absolute'"
                          >
                          <template #title>
                              <span>Item {{i+1}} {{(collect[i]&&collect[i]["name"]&&("| "+collect[i]["name"]))||""}}</span>
                          </template>
                          <template #content>
                              <AppSchemaResolver 
                                  v-model="collect[i]"
                                  :schema="schema[0]" 
                                  :rules="rules[0]"
                                  :attributes="attributes[0]"
                                  :parentdata="collect||parentdata"
                                  :incollect="collect[i]"
                                  :arrkey="'Item '+(i+1)"
                                  :index="i"
                                  :preindex="preindex+i"
                                  class="border p-2"
                                  >
                              </AppSchemaResolver>
                          </template>
                      </AppAccordion>
                  </template>
                  <div v-else v-for="(obj,i) in (collect||[])" :key="i">
                      <AppInput
                          :label="'Item '+(i+1)"
                          :name="'item-'+(i+1)"
                          :type="inschema[0]"
                          v-bind="getAttributes(attributes,i,attributes)"
                          v-model="collect[i]"
                          optionallabel=""
                          :class="{hidden:!canKeyVisble(i,attributes)}"
                      >
                      </AppInput>
                  </div>
                  <div class="pr-2 pb-2">
                      <AppButton type="button" @click="collect.push(getDefaultForm(inschema[0]))">Add</AppButton>
                  </div>
              </div>
          </template>
      </div>
    </div>
  </template>
  
  <script>
  export default {
      props: {
          modelValue: { type: [Object,Array], default: ()=>({}) },
          schema: { type: [Object], default: ()=>({}) },
          rules:{ type: [Array], default: ()=>({}) },
          attributes:{ type: [Array], default: ()=>({}) },
          parentdata:{ type: [Array,Object], default: ()=>(null) },
          incollect:{ type: [Array,Object], default: ()=>(null) },
          arrkey:{type:[String], default:""},
          index:{type:[Number], default:0},
          preindex:{type:[String], default:""},
          entry:{type:Boolean, default:false}
      },
      emits: ["update:modelValue"],
      data() {
          return {
              node:this,
              collect:this.incollect?this.incollect:this.getDefaultForm(this.schema||{})
          }
      },
      computed:{
          prei(){
              return this.preindex?this.preindex+'.':''
          },
          defaultCollect() {
              return this.getDefaultForm(this.schema||{});
          },
          groups() {
              if(typeof this.schema == "object") {
                  let items = Object.map(
                      Object.entries(this.schema)
                      .map(([key,value])=>({key,value,type:typeof value=="string"?value:"custom"}))
                      .group('type'),
                      (arr)=>arr.reduce((o,n)=>({...o,[n.key]:n.value}),{})
                  );
                  return this.customSort(Object.keys(items),["select","text","switch","number","date"]).map((n)=>(items[n]));
              } else {
                  return {
                      [this.schema]:{
                          [this.arrkey]:this.schema
                      }
                  }
              }
              
          }
      },
      updated() {
          this.debounce().then(()=>{
              this.$emit("update:modelValue", this.collect);
          });
      },
      methods: {
          customSort(arr1, arr2) {
              const posMap = new Map();
              for (let i = 0; i < arr2.length; i++) {
                  posMap.set(arr2[i], i);
              }
              arr1.sort((a, b) => {
                  const posA = posMap.has(a) ? posMap.get(a) : arr2.length;
                  const posB = posMap.has(b) ? posMap.get(b) : arr2.length;
                  return posA - posB;
              });
              return arr1;
          },
          checkDuplicate(val, arr, key, callback) {
              let index = arr.findIndex(obj=>obj[key]==val);
              console.log({checkDuplicate:val,arr,index,key});
              if(index>=0) {
                  return this.checkDuplicate(callback(val),arr,key,callback);
              } else {
                  return val;
              }
          },
          addItem(collect,key,attribute,field) {
              let len = collect[key].length;
              let oldvalue = field[0];
              let idKey = Object.keys(oldvalue).find(k=>['sl','id'].includes(k));
              let value = this.getDefaultForm(oldvalue);
              let nsl = '';
              if(idKey&&len) {
                  nsl = (collect[key][len-1][idKey]||0)-0+1;
              } else if(idKey&&len==0) {
                  nsl = 1;
              }
              if(nsl){ nsl = this.checkDuplicate(nsl,collect[key],idKey,(val)=>(val+1)); value[idKey] = nsl;}
              this.collect[key].push(value)
          },
          cloneItem(collect,key,i,attribute,field) {
              confirm({success:false}).promise.then(() => {
                  let len = collect[key].length;
                  let newobject = Object.deepClone(collect[key][i]);
                  if(newobject.name) {
                      newobject.name = newobject.name+" cloned";
                  } else if(newobject.hasOwnProperty("name")){
                      newobject.name = `Item ${i} cloned`;
                  }
                  let idKey = Object.keys(newobject).find(k=>['sl','id'].includes(k));
                  let nsl = '';
                  if(idKey&&len) {
                      nsl = (newobject[idKey]||0)-0+1;
                  } else if(idKey&&len==0) {
                      nsl = 1;
                  }
                  if(nsl) { 
                      nsl = this.checkDuplicate(nsl,collect[key],idKey,(val)=>(val+1)); 
                      newobject[idKey] = nsl;
                  }
                  collect[key].push(newobject);
              });
          },
          dragStart(index) {
              this.$el.draggingIndex = index;
          },
          dragOver(event) {
              event.preventDefault();
          },
          drop(event) {
              event.preventDefault();
              const newIndex = event.target.getAttribute("data-index");
              this.moveItem(this.draggingIndex, newIndex);
              this.draggingIndex = null;
          },
          moveItem(oldIndex, newIndex) {
              const item = this.items.splice(oldIndex, 1)[0];
              this.items.splice(newIndex, 0, item);
          },
          hasScopePermission(scopes) {
              return scopes.reduce((flag,scope)=>flag||this.loggeduser.roles.includes(scope),false);
          },
          hasScopeType(scope) {
              return scope && ((scope instanceof Array && scope.length)||typeof scope == "string");
          },
          canDelete(obj) {
              let flag = [undefined].includes(obj);
              if(obj) {
                  flag = [undefined,true].includes(obj.candelete);
              }
              if(obj && this.hasScopeType(obj.delscope)) {
                  let scopes = obj.delscope instanceof Array?obj.delscope:obj.delscope.split(',').filter(v=>v);
                  let scopeflag = this.hasScopePermission(scopes);
                  flag = scopeflag&&flag;
              }
              return flag;
          },
          canAdd(obj) {
              let flag = [undefined].includes(obj);
              if(obj) {
                  flag = [undefined,true].includes(obj.canadd)
              }
              if(obj && this.hasScopeType(obj.addscope)) {
                  let scopes = obj.addscope instanceof Array?obj.addscope:obj.addscope.split(',').filter(v=>v);
                  let scopeflag = this.hasScopePermission(scopes);
                  flag = scopeflag&&flag;
              }
              return flag;
          },
          canDraggable(obj) {
              let flag = [undefined].includes(obj);
              if(obj) {
                  flag = [undefined,true].includes(obj.draggable);
              }
              if(obj && this.hasScopeType(obj.dragscope)) {
                  let scopes = obj.dragscope instanceof Array?obj.dragscope:obj.dragscope.split(',').filter(v=>v);
                  let scopeflag = this.hasScopePermission(scopes);
                  flag = scopeflag&&flag;
              }
              return flag;
          },
          canArrayVisible(obj) {
              let flag = true;
              if(obj && this.hasScopeType(obj.scope)) {
                  let scopes = obj.scope instanceof Array?obj.scope:obj.scope.split(',').filter(v=>v);
                  let scopeflag = this.hasScopePermission(scopes);
                  flag = scopeflag&&flag;
              }
              return flag;
          },
          canKeyVisble(key,attributes) {
              let scope = this.getScope(key);
              let scopes = scope.split(',').filter(v=>v);
              return scopes.length?this.hasScopePermission(scopes):true;
          },
          getScope(key) {
              let match = key.match(/_\$scope_(.*)$/);
              return match?match[1]:"";
          },
          openMoreDrop(e) {
              let trigger = e.target;
              if(trigger.tagName.toLowerCase()=="em") {
                  let ele = trigger.nextSibling;
                  if(ele.classList.contains('hidden')) {
                      ele.classList.remove('hidden');
                  } else {
                      ele.classList.add('hidden');
                  }
              }
          },
          pasteItem(collect,key) {
              let value  = prompt("Enter the JSON value");
              try {
                  let content = JSON.parse(value);
                  collect[key] = rmerge(collect[key],content) 
              } catch (error) {
                  this.alert("Paste Failed");
                  console.error('Failed to copy text: ', error,value);
              }
          },
          compyItem(collect,key){
              const content = JSON.stringify(collect[key]);
              try {
                  navigator.clipboard.writeText(content);
                  console.log('Text copied to clipboard',content);
                  sweetAlert("Copied");
              } catch (err) {
                  this.alert("Copy Failed");
                  console.error('Failed to copy text: ', err);
              }
          },
          removeItem(key,i) {
              confirm({success:false}).promise.then(() => {
                  this.collect[key] = this.collect[key].filter((o,idx)=>idx!=i);
              });
          },
          getLabel(attributes,key) {
              let attribute = this.getAttributes(attributes,key);
              return (attribute&&attribute['data-label']) || (key||'').toTitleCase();
          },
          getInputHeight(attributes,key) {
            let attribute = this.getAttributes(attributes,key);
            return attribute?.height;
          },
          getInputDescription(attributes,key) {
            let attribute = this.getAttributes(attributes,key);
            return attribute?.description;
          },
          getAttributes(attributes,key,wa){
              let {node,parentdata,collect}  = this;
              if((attributes+'').startsWith("(")) {
                  let func = attributes.callFunc(node).run(wa,key,collect,parentdata);
                  return func;
              } else if((attributes+'').startsWith("{")) {
                  let attributesValues =  JSON.parse(attributes);
                  let attributeObj = Object.map(attributesValues,(val,key)=>{
                      if(key.isEvent() && val.isJSON()) {
                          return (value)=>val.parse().callFunc(node).run(value,key,this);
                      } if(key.isEvent()) {
                          return (value)=>val.callFunc(node).run(value,key,this);
                      } else {
                          return val;
                      }
                  });
                  return attributeObj;
              } else {
                  return {};
              }
          },
          getDefaultForm(data) {
              if(typeof data == "string") {
                  switch(data) {
                      case "text": return "";
                      case "switch": return false;
                      case "number": return 0;
                  }
                  return "";
              }
              let keys = Object.keys(data);
              let isArray = keys[0] && !isNaN(keys[0])?true:false;
              if(isArray) {
                  return Object.values(data).map((obj)=>this.getDefaultForm(obj));
              } else {
                  return Object.map(data,(val,key)=>{
                      //console.log({val,key});
                      if(val == "number") {
                          return 0;
                      } else if(val == "switch") {
                          return false;
                      } else if(typeof val == "string") {
                          return "";
                      } else if(val instanceof Array && val.length>=1) {
                          return [];
                          let value = val[0];
                          return [typeof value=="string"?"":this.getDefaultForm(value)];
                      } else {
                          return this.getDefaultForm(val);
                      }
                  })
              }
              
          },
          getNewCollect(modelValue=this.modelValue) {
              let olddata = this.getDefaultForm(this.schema||{});
              let newdata = modelValue;
              let result = smerge(olddata,modelValue);
              //console.log({olddata,newdata,result});
              return result;
          }
      },
      watch: {
          modelValue(modelValue) {
              let updated = JSON.stringify(this.collect)!=JSON.stringify(modelValue) && !Object.isEmpty(modelValue);
              if(updated) {
                  this.collect = this.getNewCollect(modelValue);
              } else if(Object.isEmpty(modelValue) && this.entry) {
                  let newcollect = this.getNewCollect();
                  if(!Object.isEmpty(newcollect)) {
                      this.debounce().then(()=>{
                          this.$emit("update:modelValue",this.getNewCollect());
                      });
                  }
              }
          },
          incollect(incollect) {
              this.collect = incollect;
          },
          collect(value) {
              //console.log("subtype",value.subtype);
          },
          schema(schema) {
              this.collect = this.getDefaultForm(schema||{});
          }
      },
      mounted() {
          window.AppSchemaResolver = this;
          this.$el.vnode = this;
          let newcollect = this.getNewCollect();
          Object.entries(this.attributes).map(([key,value])=>{
              if(value instanceof Array && value.length==2 && newcollect[key] instanceof Array && newcollect[key].length==0) {
                  let defaultArray = value[1].default || [];
                  newcollect[key] = defaultArray;
              }
          });
          console.log(`update:modelValue arraykey${this.preindex} - ${this.arrkey}`,JSON.stringify(newcollect));
          this.$emit("update:modelValue", newcollect);
      }
  }
  </script>
  
  <style>
  
  </style>