<template>
	<div>
		<div class="flex">
			<AppAutocomplete ref="autocomplete" :name="$attrs.name" v-bind="$attrs" placeholder="Search file" v-model="file" >
				<template #option="{option}">
					<div class="flex">
						<span  class="w-12 h-12 bg-contain bg-no-repeat inline-block rounded" :style="'background-image: url('+option.gardedurl+'&asimg=1'+');'"></span>
						<span>{{ option.label }}</span>
					</div>
				</template>
			</AppAutocomplete>
			<!-- <input type="text" placeholder="Enter File Ids" :name="$attrs.name" v-model="file" class="text-gray-700 dark:text-white dark:bg-gray-800 grow rounded py-2 px-3" > -->
			<div class="grow">
				<AppButton @click="$refs.file.click()" class="whitespace-nowrap" >
					Pick File <em class="ml-2 fa fa-upload"></em>
				</AppButton>
			</div>
			<div class="flex">
				<span class="relative pr-2 border" v-for="o in urlpath" >
					<a :href="o.gardedurl" target="_blank" class="inline-block" >
						<span  class="w-12 h-12 bg-contain bg-no-repeat inline-block rounded" :style="'background-image: url('+ (typeof o === 'string' ? o : o.gardedurl) + '&asimg=1'+');'"></span>
					</a>
					<em class="fa fa-trash absolute -top-1 -right-1 cursor-pointer" @click="deleteImage(o)" ></em>
				</span>
			</div>
		</div>
		<input ref="file" type="file" class="hidden" @change="upload" />
	</div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
	inheritAttrs: false,
	emits: ['update:modelValue'],
	data() {
		return {
			openGrid: false,
			urlpath:[],
			imageList: [],
			search: {
				name: "",
				limit: 100,
				offset: 0
			},
			file: this.modelValue+'',
			ogfile: null
		}
	},

	props: {
		modelValue: {
			type: [String, Object, File, Array],
			default: null
		},
		accept: {
			type: String,
			default: null
		}
	},

	components: {

	},

	computed: {
		modelValueProp() {
			return this.modelValue;
		}
	},
	watch: {
		modelValueProp(value) {
			this.file = value;
		},
		file:{
			immediate: true,
			handler:function (value) {
				if(value && value.split(',').length) {
					if (value.startsWith('http')) {
						this.urlpath = value.split(',');
					} else {
						let promises = value.split(',').map(async (id)=>{
							return await this.apiGetFile({ id });
						});
						Promise.all(promises).then((files)=>{
							this.urlpath = files.map(({data})=>({...data}));
						});
					}
				} else if(!value) {
					this.urlpath = [];
				}
			}
		}
	},
	methods: {
		deleteImage(item) {
			let self = this;
			confirm("Please confirm your action.").promise.then(() => {
				this.apiFileRemove({ id: item.id }).then(() => {
					this.urlpath = this.urlpath.filter(o=>o.id!=item.id);
					self.fetchImages();
				});
			});
		},
		onImageSearch(e) {
			this.debounce(500).then(() => {
				this.search.
					this.fetchImages({
						name: e.target.value
					}).then(() => {

					});
			});
		},
		fetchImages(data = {}) {
			return new Promise((reslove) => {
				this.apiGetFiles({
					...this.search,
					...data
				}).then(({ data: response }) => {
					this.imageList = response.data;
				}).finally(() => {
					reslove();
				});
			})
		},
		openImageGrid() {
			this.fetchImages().then(() => {
				this.openGrid = true;
			});
		},
		removeImage() {
			this.$emit('update:modelValue', '');
			this.openGrid = false;
			this.search.name = "";
		},
		selectImage(item) {
			this.$emit('update:modelValue', item.id);
			this.openGrid = false;
			this.search.name = "";
		},
		uploadSingle(file,index) {
			return new Promise((reslove)=>{
				this.apiFileUpload({ file: file }).then(({fileId,gardedurl,extention,url}) => {
					this.urlpath[index] = {
						id:fileId,
						gardedurl:gardedurl,
						extention,
						url
					};
					this.urlpath = [...this.urlpath];
					reslove(this.urlpath[index]);
				});

			})
		},
		upload(event) {
			const value = event.target.files || event.dataTransfer.files;
			console.log({value});
			let promises = [...value].map(async (file,i) => {
				this.ogfile = file;
				return await this.uploadSingle(file,i);
			});
			Promise.all(promises).then((files)=>{
				this.file = files.map(o=>this.getFileValue(o)).join(',');
				this.$emit('update:modelValue', this.file);
			});
		},
		getFileValue(obj) {
			if (obj.url.startsWith('http')) {
				return obj.url;
			} else {
				return window.location.origin+`/app/storage?file_path=${obj.url.split('/storage').join('')}`;
			}
		}
	}
})
</script>