const files  = import.meta.glob('../**/*.api.js', { eager: true });
const apis = Object.keys(files).map((key)=>({
    ...files[key].default
}));
import apisetup from "./apisetup";
import apibase from "./api.base";
const apiMethods = {
    ...apibase,
    ...apis
}
export { apisetup as api, apiMethods as apis };
export default apiMethods;