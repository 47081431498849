import * as prettier from "prettier";

window.prettier = prettier;
console.log("prettier",prettier);

window.device =  function() {
    return (window.config.devices||[]).find((device)=>{
        return window.innerWidth>=device.minWidth &&  window.innerWidth<device.maxWidth;
    })||{name:"notset-device"};
}

window.has = (val, def) => {
    if (val == null || val === '') return def;
    if (Array.isArray(val) && !val.length) return def;
    if (val instanceof Date && isNaN(val.getTime())) return def;
    if (typeof val === 'object' && !Object.keys(val).length) return def;
    return val;
};

window.urlparams = function () {
    return window.location.href.url().params;
}

window.rmerge = function(source, comparator) {
    let result = { ...source };
    for (const key in comparator) {
        if (!comparator.hasOwnProperty(key)) continue;
        const value = comparator[key];
        if (value == null || value === '') continue;
        if (Array.isArray(value) && Array.isArray(result[key])) {
            result[key] = rmerge(result[key], value);
        } else {
            result[key] = value;
        }
    }
    return result;
};

window.smerge = function (structure, data) {
    let result = structure instanceof Array ? [] : {};
    result = typeof structure !== "object" ? data : result;
    const getValue = (v, k) => (v?.[k]);
    if (structure instanceof Array) {
        let ardata = (data instanceof Array) ? data : [];
        result = ardata.map(v => smerge(structure[0], v));
    } else if (typeof structure == "object") {
        Object.keys(structure).forEach((key) => {
            if (typeof structure[key] === 'object') {
                result[key] = smerge(structure[key], getValue(data, key));
            } else {
                result[key] = getValue(data, key) || structure[key];
            }
        })
    } else {
        result = data || structure;
    }
    return result;
}

window.keyify = function (obj, prefix = '') {
    let result = {};
    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            let newKey = prefix ? `${prefix}.${key}` : key;
            if (typeof obj[key] === 'object' && obj[key] !== null && !Array.isArray(obj[key])) {
                Object.assign(result, keyify(obj[key], newKey));
            } else if (Array.isArray(obj[key])) {
                obj[key].forEach((item, index) => {
                    if (typeof item === 'object' && item !== null) {
                        Object.assign(result, keyify(item, `${newKey}.${index}`));
                    } else {
                        result[`${newKey}.${index}`] = item;
                    }
                });
            } else {
                result[newKey] = obj[key];
            }
        }
    }
    return result;
}

window.unkeyify = function  (obj) {
    const firstKey = Object.keys(obj)[0];
    let result = (!isNaN(parseInt(firstKey, 10))) ? [] : {};
    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            let keys = key.split('.');
            let temp = result;
            for (let i = 0; i < keys.length; i++) {
                if (i === keys.length - 1) {
                    temp[keys[i]] = obj[key];
                } else {
                    // If the next key in the sequence is a number, we assume the current key is an array
                    if (!isNaN(parseInt(keys[i + 1], 10))) {
                        temp[keys[i]] = temp[keys[i]] || [];
                    } else {
                        temp[keys[i]] = temp[keys[i]] || {};
                    }
                    temp = temp[keys[i]];
                }
            }
        }
    }
    return result;
}

window.runjs = function (funstring, ret = true, ...args) {
    let result = window.getEvaled(funstring, ret, ...args);
    return result instanceof Function ? result(...args) : result;
};

window.getEvaled = function (funstring, ret = true, ...args) {
    return (new Function((ret ? "return " : "") + funstring))(...args)
}

window.callFunc = function (funstring, context) {
    let func = (new Function("return " + funstring))();
    let bindfunc = (function (...args) { return func ? func(...args) : () => (false) }).bind(context);
    return { run: (...args) => (funstring && bindfunc ? bindfunc(...args) : true) };
}

String.prototype.callFunc = function (context) {
    return window.callFunc(this, context);
}

window.checkJsonSample = function (json, pkey = "") {
    //console.log("checkJsonSample",{json,pkey,isobject:typeof json == "object"});
    if (typeof json == "object" && json && !(json instanceof Array)) {
        return Object.map(json, (val, key) => {
            //console.log("checkJsonSample",{val,key});
            if ([null, undefined].includes(val)) {
                return val;
            } else if (val?.type && val?.attributes) {
                if (typeof val.attributes != "string") {
                    val.attributes = Object.map(val.attributes, (val, key) => {
                        let isEvent = key.match("^on+[A-Z][a-z][a-zA-z]*$");
                        if (isEvent) { return val.toString(); }
                        else { return val; }
                    });
                    val.attributes = JSON.stringify(val.attributes);
                }
                return JSON.stringify(val);
            } else if (val instanceof Array) {
                let arr1val = val[1]||{};
                //console.log("checkJsonSample",{"val[1]":val[1],arr1val});
                if (arr1val && typeof arr1val === "object" && typeof arr1val.attributes != "string" && Object.keys(arr1val).length) {
                    arr1val.attributes = Object.map(arr1val.attributes, (val, key) => {
                        let isEvent = key.match("^on+[A-Z][a-z][a-zA-z]*$");
                        if (isEvent) { return val.toString(); }
                        else { return val; }
                    });
                    arr1val.attributes = JSON.stringify(arr1val.attributes);
                } else if(typeof arr1val !== "object") {
                    arr1val = {};
                }
                if (val.length) {
                    //console.log("checkJsonSample",{arr1val});
                    return [
                        window.checkJsonSample(val[0], 0),
                        ...(arr1val ? [arr1val] : [])
                    ];
                } else {
                    return [];
                }
            } else if (typeof val === "object") {
                return window.checkJsonSample(val, key);
            } else {
                return val;
            }
        });
    } else if (json instanceof Array && json.length && typeof json[0] == "object") {
        return json.map(v => window.checkJsonSample(v, pkey));
    } else {
        return json;
    }
}

window.getSchemaBuilder = function (json, selected = "default", generator = {}, path = "") {
    generator[selected] = [];
    const hasEnds = (keys, key) => keys.reduce((o, n) => (o || key.endsWith(n)), false);
    const hasStarts = (keys, key) => keys.reduce((o, n) => (o || key.startsWith(n)), false);
    const getType = (val, key) => {
        let filext = [".png", ".jpeg", ".jpg", ".html"];
        let isObject = (val && typeof val == "object");
        let condition = (isObject && val.condition) ? val.condition : {
            ...(val ? (val[1] || {}) : {})
        };
        if (val && (val instanceof Array || typeof val == "object")) {
            let name = `${(key || '').camelCase()}-${''.generateId(6)}`;
            let isArray = val instanceof Array;
            delete val.condition;
            return {
                type: (isArray) ? `[${name}]` : `{${name}}`,
                custom: true,
                name,
                ...condition
            }
        } else if (val && typeof val == "string" && typeof val.parse() == "object") {
            let obj = val.parse();
            return {
                ...obj,
                type: obj.type || "text",
                custom: false,
                name: "",
            }
        } else if (typeof val == "string" && val.endsWith(".inhtml")) {
            return {
                type: "code",
                custom: false,
                name: ""
            }
        } else if (filext.reduce((o, n) => o || (val && typeof val == "string" && val.endsWith(n)), false) || hasEnds(["bg", "img", "image", "btn", "icon", "page"], key)) {
            return {
                type: 'file',
                custom: false,
                name: ""
            }
        } else if (val && typeof val == "string" && val.length >= 50 || key.endsWith("description")) {
            return {
                type: 'textarea',
                custom: false,
                name: ""
            }
        } else if (val && typeof val == "string" && (val.startsWith("#")||hasEnds(['color'],val)) && val.length > 6 && val.length < 9 || hasEnds(["color"], key)) {
            return {
                type: 'color',
                custom: false,
                name: ""
            }
        } else if ([true, false].includes(val) || hasStarts(["is_", "has_"], key)) {
            return {
                type: 'switch',
                custom: false,
                name: ""
            }
        } else if (!isNaN(val)) {
            return {
                type: 'number',
                custom: false,
                name: ""
            }
        } else {
            return {
                type: 'text',
                custom: false,
                name: ""
            }
        }
    };
    if (typeof json == "object") {
        Object.entries(json).forEach(([key, val]) => {
            let getpath = (index="")=>(path?path+"."+key+index:key+index);
            let { type, custom, name, rule = "", attributes = "" } = getType(val, key);
            let title = (typeof val == "string" && val.startsWith("info:")) ? val.split("info:").join('') : "";
            attributes = attributes ? attributes : JSON.stringify({ title });
            //console.log({key,val});
            delete attributes.path;
            if(attributes.isJSON()) {
                attributes = attributes.parse();
                attributes.path = getpath();
                attributes = JSON.stringify(attributes);
            }
            //console.log("getSchemaBuilder",{key,val, custom,type},attributes.path);
            let genObj = {
                key: key,
                type: type,
                rule,
                attributes
            };
            if (custom) {
                getSchemaBuilder(val instanceof Array ? (val[0] || {}) : val, name, generator, getpath(val instanceof Array?".[i]":""));
            }
            if(isNaN(key)) {
                generator[selected].push(genObj);
            } else {
                generator[selected].push(type);
            }
        }, {});
    } else {
        let { type } = getType(json, "");
        generator[selected].push(type);
    }
    return {
        generator
    };
}

window.getSchemaMapper = function (json, root = "") {
    let isArray = json instanceof Array;
    let isObject = json && typeof json == "object";
    let preattach = root ? root + "." : "";
    if (isArray) {
        let compoundkey = preattach + "[i]";
        if (typeof json[0] == "object") {
            return [
                ...json.map((o) => getSchemaMapper(o, compoundkey)),
                ...([{ name: root }])
            ];
        } else {
            return preattach.slice(0, -1);
        }
    } else if (isObject) {
        let data = Object.map(json, (val, key, obj, ref) => {
            let ukey = key;
            let $datapat = /_\$(.*)$/;
            let [hasmatch = false, matched] = (key.match($datapat) || []);
            if (hasmatch && matched == "redis") { return undefined; }
            else if (hasmatch && matched == "redis_s3") { ref.key = key.replace($datapat, ''); }
            else if (hasmatch && matched.startsWith('scope_')) { ref.key = key.replace($datapat, ''); }
            let compoundkey = preattach + ukey;
            //console.log({ compoundkey, key, val, json, hasmatch, matched, ref });
            return (val && typeof val == "object") ? getSchemaMapper(val, compoundkey) : compoundkey
        });
        return Object.filter(data, (v) => (![undefined].includes(v)));
    } else if (["color"].includes(json)) {
        return root
    } else {
        return "";
    }
}

window.parseInput = function (input) {
    let arrout = [];
    let output = {};
    Object.entries(Object.deepClone(input)).forEach(([key, value]) => {
        output = Object.setNested((/^\d.*/).exec(key) ? arrout : output, key, value);
        arrout = output;
    });
    return JSON.parse(JSON.stringify(output));
}

window.stringify = function (value) {
    if (typeof value === 'string') {
        return '"' + value.replace(/"/g, '""') + '"';
    } else if (Array.isArray(value)) {
        let elements = value.map(window.stringify);
        return '[' + elements.join(',') + ']';
    } else if (typeof value === 'object' && value !== null) {
        let pairs = [];
        for (let key in value) {
            if (value.hasOwnProperty(key)) {
                let keyValue = window.stringify(value[key]);
                pairs.push('"' + key + '":' + keyValue);
            }
        }
        return '{' + pairs.join(',') + '}';
    } else if (typeof value == "function") {
        return value.toString();
    } else {
        return String(value);
    }
}