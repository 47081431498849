import CryptoJS from 'crypto-js';
// import Buffer from 'buffer';
// window.CryptoJS = CryptoJS;
String.prototype.encodeBase64 = function () {
    let str = this;
    let bin = Array.from(str).map((char) =>
        char.charCodeAt(0).toString(2).padStart(8, '0')
    ).join('');
    return bin.match(/.{1,6}/g).map((b) =>
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/="[parseInt(b.padEnd(6, '0'), 2)]
    ).join('') + "=".repeat((str.length % 3) ? 3 - (str.length % 3) : 0);
};

String.prototype.decodeBase64 = function () {
    const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";
    let str = this.replace(/=+$/, '');
    let bin = str.split('').map(ch =>
        chars.indexOf(ch).toString(2).padStart(6, '0')
    ).join('');
    let bytes = bin.match(/.{8}/g).map(byte => parseInt(byte, 2));
    return decodeURIComponent(bytes.map(byte => 
        '%' + byte.toString(16).padStart(2, '0')
    ).join(''));
};

String.prototype.encrypt = function (key = import.meta.env.VITE_ENCRYPTION_KEY) {
    const ENCRYPTION_KEY = CryptoJS.enc.Utf8.parse(key); // 32 bytes for AES-256
    const INIT_VECTOR = CryptoJS.enc.Utf8.parse(import.meta.env.VITE_INIT_VECTOR); // 16 bytes for AES
    const data = this;
    const encrypted = CryptoJS.AES.encrypt(data, ENCRYPTION_KEY, {
        iv: INIT_VECTOR,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
    const base64Encrypted = encrypted.ciphertext.toString(CryptoJS.enc.Base64);
    return base64Encrypted;
}

String.prototype.decrypt = function (key=import.meta.env.VITE_ENCRYPTION_KEY) {
    const ENCRYPTION_KEY = CryptoJS.enc.Utf8.parse(key); // 32 bytes for AES-256
    const INIT_VECTOR = CryptoJS.enc.Utf8.parse(import.meta.env.VITE_INIT_VECTOR); // 16 bytes for AES
    const encryptedData = this;
    const decrypted = CryptoJS.AES.decrypt(encryptedData, ENCRYPTION_KEY, {
        iv: INIT_VECTOR,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
    const decryptedString = decrypted.toString(CryptoJS.enc.Utf8);
    return decryptedString;
}

String.prototype.url = function () {
    try {
        let str = this.includes("http") ? this : (window.location.origin + this);
        let url = new URL(str);
        let splits = url.hash.split("?");
        url.search = url.search+(url.search?'&':'?')+(splits[1]||'');
        if(splits.length==2) {
            url.hash = splits[1];
        }
        url.params = Object.fromEntries(url.searchParams.entries());
        return url;
    } catch (e) {
        return this;
    }
}
String.prototype.cap2space = function() {
    return this.split('').map((ch)=>{
        if('A'.charCodeAt()>=ch.charCodeAt()>='Z'.charCodeAt()) { return ch+' '; }
        return ch;
    }).join('');
}
Array.prototype.splitBy = function(delimit=" ") {
    return this.map(val=>val.split(delimit)).flat().map(v=>(v||'').trim()).filter(v=>v);
}
String.prototype.splitBy = function(delimit=" ") {
    return [this].splitBy(delimit);
}
String.prototype.splitByArr = function(delimiters=[' ','_','-']) {
    let arr = [this];
    delimiters.map((delimit)=>{
        arr = arr.splitBy(delimit);
    })
    return arr;
}

String.prototype.spaceCase = function() {
    return this.cap2space().splitByArr().join(' ');
}

String.prototype.snakeCase = function() {
    return this.cap2space().splitByArr().join('_').toLowerCase();
}

String.prototype.camelCase = function() {
    return this.cap2space().splitByArr().map((word,i)=>{
        return i==0?word.toLowerCase():word[0].toUpperCase()+word.slice(1).toLowerCase();
    }).join('').toLowerCase();
}

String.prototype.toTitleCase = function() {
    return this.cap2space().splitByArr().map(word=>{
        return word[0].toUpperCase()+word.slice(1).toLowerCase();
    }).join(' ');
}

String.prototype.emphasis = function(len=13){
    let res = this.substr(0,len);
    return (res.length<=(len-3))?res:(res.substr(0,len-3)+"...");
}

String.prototype.cur = Number.prototype.cur = function(deci,country="INR"){
    let formater = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: country,
    });
    return formater.format(this.parse());
}

String.prototype.rounded = Number.prototype.rounded = function(deci=0){
    return this.parse().toFixed(deci).parse();
}

String.prototype.parse = Number.prototype.parse = function() {
    try{ return JSON.parse(this)}catch(e){ 
        return Number(this);
    };
}

String.prototype.removeExt = function() {
    return this.split('.')[0];
}

String.prototype.basename = function(sep='/',index=1) {
    return (this.split(sep)[index]||this).removeExt();
}

String.prototype.file = function(sep='/') {
    return this.substring(this.lastIndexOf(sep)+1);
}

String.prototype.filename = function(sep='/') {
    return this.file(sep).removeExt();
}

String.prototype.env = function() {
    return atob(this).parse();
}

String.prototype.decode = function () {
    return decodeURIComponent(escape(this.decodeBase64()));
}

String.prototype.encode = function () {
    return unescape(encodeURIComponent(this)).encodeBase64();
}


String.prototype.access = function(token=a824?.env()?.STATIC_TOKEN){
    return this+((token&&"?"+("access".encode())+"="+token.encode())||'');
}

String.prototype.trimLeft = function(charlist="\s") {
    return this.replace(new RegExp("^[" + charlist + "]+"), "");
};

String.prototype.trimRight = function(charlist="\s") {
    return this.replace(new RegExp("[" + charlist + "]+$"), "");
};

String.prototype.trim = function(charlist=" ") {
    return this.trimLeft(charlist).trimRight(charlist);
};

String.prototype.uniqueId = function(len=10) {
    return Math.random().toString().slice(2).slice(0,len);
} 

String.prototype.classlist = function(obj) {
    let str = this;
    return new class {
        constructor() {
            let list = str.split(" ").reduce((o,n)=>({...o,[n]:true}),{})
            this.list = list;
            if(this.isObject(obj)) { this.list  = Object.assign(list,obj); }
            else if(typeof obj == "string") { this.list[obj]=true }
        }
        isObject(value) {
            return typeof value == "object" && !Array.isArray(value);
        }
        replace(find,newval) {
            if(typeof find == "string") {
                this.list[find] = false;
                this.list[newval] = true;
            } else if(typeof find == "object" && !Array.isArray(find)) {
                this.list = Object.assign(this.list,find);
            }
            return this;
        }
        add(val) { this.list[val] = true; return this; }
        remove(val) { this.list[val] = false; return this; }
        toString() { return Object.className(this.list) }
        string() { return this.toString(); }
        str() { return this.toString(); }
    };
}

String.prototype.download = function (fileName = "download") {
    let a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    let blob = new Blob([this], { type: "octet/stream" }),
        url = window.URL.createObjectURL(blob);
    a.id = "download";
    a.href = url;
    a.target = "_blank";
    a.download = fileName;
    a.click();
    a.remove()
    window.URL.revokeObjectURL(url);
}

String.prototype.generateKey = function (length = +this) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';
    let randomString = '';
    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        randomString += characters.charAt(randomIndex);
    }
    return randomString;
}

String.prototype.generateId = function (len = +this) {
    len = len || 16;
    let arr = new Uint8Array((len || 40) / 2)
    window.crypto.getRandomValues(arr);
    return Array.from(arr, Number.prototype.dec2hex).join('').encodeBase64()
}

String.prototype.isJSON = function () {
    try { return JSON.parse(this) } catch (e) {
        return undefined;
    }
}

String.prototype.isEvent = function() {
    return this.match(/(^on[A-Z][a-zA-Z0-9]*)/);
}

String.prototype.jsonPretty = function (arg1 = null, arg2 = '\t') {
    return JSON.stringify(JSON.parse(this), arg1, arg2);
}