<template>
    <div class="AppDataTable">
        <div class="flex items-center">
            <slot name="breadcum"></slot>

            <div class="first:ml-auto flex gap-4">
                <AppButton v-if="intblattrs.importable" @click="$refs.importFileIp.click()">
                    Import <em class="fa fa-upload"></em>
                </AppButton>
                <input
                    v-if="intblattrs.importable"
                    ref="importFileIp"
                    type="file"
                    accept=".csv"
                    class="hidden"
                    @change="onImport"
                />
                <AppButton v-if="intblattrs.exportable" @click="onExport">
                    Export <em class="fa fa-download"></em>
                </AppButton>
                <AppButton v-if="canadd" @click="onAdd">Add</AppButton>
            </div>
        </div>
        <AppTable ref="table" v-bind="intblattrs" >
            <template #left-perpage>
                <div>
                    <div class="px-2 cursor-pointer" @click="moreoperation = !moreoperation">
                        <em class="fa fa-ellipsis-v cursor-pointer ml-auto"></em>
                    </div>
                    <div class="relative" v-if="moreoperation">
                        <div
                            class="more-operations md:rounded bg-white border border-gray-100 dark:bg-gray-900 dark:border-gray-900 mb-4 absolute top-0 left-0 z-10">
                            <div v-for="(column, i) in columns" :key="i" class="fields flex gap-2 p-2">
                                <div>
                                    <input class="h-5 w-5" type="checkbox" v-model="visibleflds[column]"
                                        :checked="visibleflds[column]" @change="changeVisibles()" />
                                </div>
                                <div class="flex-grow my-auto whitespace-nowrap">
                                    {{ column }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template v-for="(slot, key) in $slots" #[key]="row">
                <slot :name="key" v-bind="row"></slot>
            </template>
        </AppTable>
        <AppModal v-model="open">
            <AppForm v-model="formdata" class="grow" @submit="onSubmit" >
                <div class="flex flex-wrap flex-flow gap-2">
                    <!-- flex flex-wrap flex-flow -->
                    <div :class="{
                            'grow':true,
                            'w-full':['code'].includes(field.type)
                        }" v-for="(field, i) in addables" :key="'field-'+i+'-'+field.name" >
                        <div class="flex">
                            <slot :name="'before_'+field.name+'_'+'form'" :field="field" :formdata="formdata" ></slot>
                            <AppInput v-if="!slotkeys.includes(field.name+'_'+'form')" class="grow" type="text" v-bind="field" ></AppInput>
                            <slot v-else :name="field.name+'_'+'form'" :field="field" :formdata="formdata" ></slot>
                            <slot :name="'after_'+field.name+'_'+'form'" :field="field" :formdata="formdata"></slot>
                        </div>
                    </div>
                    <br/>
                </div>
                <template #footer>
                    <div class="flex">
                        <AppButton class="ml-auto" type="submit" >
                            SUBMIT
                        </AppButton>
                    </div>
                </template>
            </AppForm>
        </AppModal>
    </div>
</template>
<script>
export default {
    props: {
        onModal: {
            type: Function,
            default: ({row}) => (row)
        },
        canadd: {
            type: Boolean,
            default: true
        },
        tblattrs: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            moreoperation: false,
            open: false,
            edit: false,
            editrow:{},
            formdata: {},
            visibleflds:{},
            visible:[]
        }
    },
    computed: {
        slotkeys() {
            return Object.keys(this.$slots);
        },
        columns() {
            return this.cols();
        },
        addables() {
            return this.infields().filter(o => !!o[1].canadd).map(v=>{
                let data = v[1];
                let modelValue = this.formdata[data.name]||data.defaultValue;
                let attrs = this.checkType(modelValue,data);
                return ({
                    ...data,
                    modelValue,
                    ...attrs,
                })
            }).sort((a,b)=>(a.index-b.index));
        },
        intblattrs() {
            return {
                ...this.tblattrs,
                visible:this.visible.length?this.visible:this.tblattrs.visible,
                onEdit:(...args)=>{
                    let [ payload ] = args;
                    let { row, i:index, filter } = payload;
                    this.editrow = row;
                    this.formdata = this.addables.reduce((c,v)=>({...c,[v.name]:row[v.name]||v.defaultValue}),this.formdata);
                    let modalRes = this.onModal({row:this.formdata,edit:true});
                    this.formdata = modalRes||this.formdata;
                    this.open = true;
                    this.edit = true;
                }
            }
        }
    },
    methods: {
        changeVisibles() {
            this.visible = Object.entries(this.visibleflds)
                .filter(([key, value]) => value)
                .map(([key]) => key);
        },
        checkValue(attrs,value,data) {
            if(!data.modelValue) {
                switch(attrs.type) {
                    case "datetime-local":
                        attrs.modelValue = new Date(value).toDateTime();
                    break;
                    case "time":
                        attrs.modelValue = new Date(value).toTime();
                    break;
                    case "date":
                        attrs.modelValue = new Date(value).toDate();
                    break;
                }
            }
            return attrs;
        },
        checkType(value,data) {
            let attrs = {type:"text"};
            if(data.type) {
                attrs.type = data.type;
            } else if(value instanceof Array) {
                attrs.type = "select";
                if(value.length>1) {
                    attrs.multiple = true;
                }
                attrs.options = (data.options && data.options.length && data.options) || value.map(v=>({value:v,label:v.toTitleCase()}));
            } else if(typeof value == "string" && (new Date(value)).toString() != "Invalid Date") {
                if(value.includes("T")) {
                    attrs.type = "datetime-local";
                } else if(value.includes(":")) {
                    attrs.type = "time";
                } else {
                    attrs.type = "date";
                }
                
            } else if(!isNaN(value) && value<1) {
                attrs.type = "switch";
            } else if(!isNaN(value)) {
                attrs.type = "number";
            } else if(typeof value == "string" && value.length >= 100) {
                attrs.type = "textarea";
            }
            attrs = this.checkValue(attrs,value,data);
            return attrs;
        },
        onAdd() {
            this.formdata = {};
            this.open = true;
            this.edit = false;
        },
        onImport(event) {
            const file = event.target.files.length ? event.target.files[0] : null;

            if (!file) {
                return;
            }

            if (!this.tblattrs.modelname) {
                console.error('Model not set for importing.');
                return;
            }

            this.apiCurd(this.tblattrs.modelname, {
                action: "importfile",
                file
            }).then(() => {
                this.alert('Successfully imported file.');
                event.target.value = '';
            });
        },
        onExport() {
            if (this.$refs.table.selectedData.length) {
                let date = new Date().toLocaleDateString();
                let time = new Date().toLocaleTimeString();
                let name = this.$refs.table.name || document.title;
                this.$refs.table.selectedData.csv().download(`${name}-export-${date}-${time}.csv`);
                return;
            }

            if (!this.tblattrs.modelname) {
                console.error('Model not set for exporting.');
                return;
            }

            this.apiCurd(this.tblattrs.modelname, {
                ...(this.tblattrs.tablename?{tablename:this.tblattrs.tablename}:{}),
                action: 'exportfile',
                where: this.tblattrs.exportFilters || {}
            }).then((response) => {
                if (response.download_url) {
                    window.open(response.download_url);
                }
            });
        },
        onSubmit(data) {
            let payload = {row:data,edata:this.editrow,i:0,filter:this?.$refs?.table?.filter_payload};
            if(this.edit) {
                this.tblattrs?.onEdit(payload);
            } else {
                this.$emit("add",payload);
            }
            this.open = false;
            this.edit = false;
        },
        cols() {
            return this?.$refs?.table?.allcols || [];
        },
        infields() {
            return Object.entries(this?.$refs?.table?.infields || {});
        },
        fetchTable() {
            return this?.$refs?.table?.onFetch();
        }
    },
    watch: {
        "tblattrs.visible"(val) {
            this.visible = val;
            if(val.length) {
                this.visibleflds = this.columns.reduce(
                    (o, n) => ({ ...o, [n]: val.includes(n) }),
                    {}
                );
            }
        }
    },
    mounted() {
        window.AppDataTable = this;
    }
}
</script>
<style>
.AppDataTable {}
</style>