<template>
    <div class="relative inline-block text-left">
        <button @click="toggleDropdown" class="px-4 py-2 flex">
            <div class="grow" v-if="selectable">
                {{ selectedOption }}
            </div>
            <div class="grow" v-else>
                <slot name="label"></slot>
            </div>
            <slot v-if="$slots.trigger" name="trigger"></slot>
            <i v-else class="fas fa-caret-down m-auto ml-2 "></i> <!-- Font Awesome icon -->
        </button>
        <div v-if="isOpen" @click="closeDropdown" class="fixed inset-0 h-screen w-screen z-10"></div>
        <div v-if="isOpen"
            class="origin-top-right absolute right-0 mt-2 w-auto rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 dark:bg-gray-800 z-10">
            <div class="py-1">
                <template v-for="(option, index) in options" :key="index" @click="selectOption(option)" >
                    <router-link v-if="option.link" :to="option.link" class="block px-4 py-2 text-sm hover:bg-gray-100 dark:hover:bg-gray-900" >
                        <i :class="(option.icon||'fa fa-circle-dot')+' inline-block w-4'"></i>
                        {{ option.label }}
                    </router-link>
                    <button v-else class="block px-4 py-2 text-sm hover:bg-gray-100 dark:hover:bg-gray-900" @click="option.onClick" >
                        <i :class="option.icon+' inline-block w-4'"></i>
                        {{ option.label }}
                    </button>
                </template>
                <div @click="closeDropdown">
                    <slot name="content"  ></slot>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    props: {
        label:{ type:String, default:"Select" },
        dclass:{ type:String, default:"" },
        selectable:{ type: Boolean, default:false },
        options: {
            type:Array,
            default:()=>([])
        }
    },
    data() {
        return {
            isOpen: false,
            selectedOption: this.label
        };
    },
    mounted() {
        window.AppDropDown = this;
    },
    methods: {
        toggleDropdown() {
            this.isOpen = !this.isOpen;
        },
        closeDropdown() {
            this.isOpen = false;
        },
        selectOption(option) {
            this.selectedOption = option;
            this.isOpen = false;
        },
    },
};
</script>
  
<style scoped>
/* Add Tailwind CSS classes or custom styles here */
</style>
  