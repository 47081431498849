Object.encodeuri = function (obj) {
    return Object.entries(obj).map(a => a[0] + "=" + a[1]).join("&");
}

Object.filter = (obj, predicate) =>
Object.keys(obj)
.filter( key => predicate(obj[key],key,obj) )
.reduce( (res, key) => (res[key] = obj[key], res), {} );

Object.map = (obj, predicate) =>
Object.keys(obj)
.map( key => ({key:key,value:predicate(obj[key],key,obj)}) )
        .reduce((res, o) => (res[o.key] = o.value, res), {});


Object.className = (obj)=>{
    return Object.keys(Object.filter(obj,v=>v)).join(' ');
}

Object.QueryString = (obj) => {
    return Object.values(Object.map(obj, (v, k) => v?`${k}=${encodeURIComponent(v)}`:'')).filter(v=>v).join("&");
};

Object.deepClone = function(obj){
    return JSON.stringify(obj).parse();
}

Object.attribute = (obj)=>{
    return Object.entries(obj).map(([key,val])=>`${key}='${val}'`).join(' ');
}

Object.setValues = (obj,name,value)=>{
    const setValues = (state,payload)=>{
        let old = state;
        let key = "";
        let arr = payload.name.split(".");
        if (arr.length > 1) {
            let tmp = state;
            arr.forEach((k) => {
                old = tmp; tmp[k] = tmp[k] || {}; tmp = tmp[k]; key = k;
            });
            old[key] = payload.value;
        } else {
            key = payload.name;
            old = state[key];
            state[key] = payload.value;
        }
        return state;
    };
    if(typeof name == "string"){
        obj = setValues(obj,{name,value});
    } else {
        obj = setValues(obj,name);
    }
    return obj;
}

Object.getValues = (obj,name)=>{
    let old = state;
    let key = name;
    let arr = name.split(".");
    if (arr.length > 1) {
        let tmp = state;
        arr.forEach((k) => {
            old = tmp; tmp[k] = tmp[k] || {}; tmp = tmp[k]; key = k;
        });
        return old[key];
    } else {
        return obj[key];
    }
}

HTMLFormElement.prototype.getData = function() {
    let entries = new FormData(this).entries();
    let obj = {};
    for (var pair of entries) {
        let key = pair[0],value = pair[1];
        let ele = this.querySelector(`[name=${key}]`);
        if(ele.getAttribute('type')=="checkbox") {
            value = value=="on"?1:value;
            value = value=="off"?0:value;
        }
        obj[key] = value;
    }
    return obj;
}

Object.setNested = (state,name,value)=>{
    let payload = { name,value};
    let old = state,tmp = old,key = "",arr = payload.name.split(".");
    if (arr.length > 1) {
        arr.forEach(k => {
            old = tmp; tmp[k] = tmp[k] || {}; tmp = tmp[k]; key = k;
        });
        old[key] = payload.value;
    } else {
        key = payload.name; old = state[key]; state[key] = payload.value;
    }
    return state;
}

Object.defVal = (val, def, ...other)=>{
    if (typeof val == "object" && !(val instanceof Array) && val !== null) {
        let name = other[0] || "";
        return val[name] === undefined ? def : val[name];
      } else if (typeof val == "function") {
        return val(this.data, this.editmode, ...other);
      } else {
        return val === undefined ? def : val;
      }
}

Object.getNested = (state,name,def)=>{
    let payload = { name };
    let old = state,tmp = old,key = "",arr = payload.name.split(".");
    if (arr.length > 1) {
        arr.forEach(k => {
            old = tmp||{}; tmp[k] = tmp[k] || {}; tmp = tmp[k]; key = k;
        });
        return Object.defVal(old[key],def);
    } else {
        key = payload.name; old =  Object.defVal(state[key],def);
    }
    return Object.defVal(old,def);
}

Object.only = (obj,required=[])=>{
    return Object.filter((obj||{}),(v,k)=>required.includes(k))
}

Object.except = (obj,excludes=[])=>{
    return Object.filter((obj||{}),(v,k)=>!excludes.includes(k))
}

Object.has = (obj,required=[])=>{
    return Object.keys(Object.only((obj||{}),required)).length==required.length;
}
Object.hasValue = (obj)=>{
    return !!Object.entries((obj||{})).map(([key,val])=>val).filter(v=>v).length;
}

HTMLElement.prototype.getParents = function() {
    let list = [];
    if(this.parentElement) {
        list.push(this.parentElement);
        return list.concat(this.parentElement.getParents());
    } else {
        return [];
    }
}

Object.sortBySubstr = (obj) => {
    const sortedProps = Object.keys(obj).sortBySubstr();
    const newObj = {};
    sortedProps.forEach((prop) => {
        newObj[prop] = obj[prop];
    });
    return newObj;
}

Object.isEmpty = (obj) => {
    return (obj && typeof obj === "object") && Object.keys(obj).length == 0 && obj.constructor instanceof Object;
}

Object.keyify = (obj, prefix = "") => {
    return Object.keys(obj).reduce((res, el) => {
        if (
            (Array.isArray(obj[el]) && obj[el].length) ||
            (typeof obj[el] === 'object' && !Object.isEmpty(obj[el]) && ![null, undefined].includes(obj[el]))
        ) {
            return [...res, ...Object.keyify(obj[el], prefix + el + '.')];
        }
        return [...res, prefix + el];
    }, []);
}