export default {
    setMutation(state,callback) {
        return new Promise((resolve)=>{
            callback(state);
            sys.setTimeout(()=>resolve(state));
        });
    },
    setStore: (...args) => {
        console.log({args},this);
        let [ state, payload ] = args;
        let old = state;
        let key = "";
        let arr = payload.name.split(".");
        if (arr.length > 1) {
            let tmp = state;
            arr.forEach((k) => {
                old = tmp; tmp[k] = tmp[k] || {}; tmp = tmp[k]; key = k;
            });
            old[key] = payload.value;
            //Vue.set(old, key, payload.value);
        } else {
            key = payload.name;
            old = state[key];
            state[key] = payload.value;
            //Vue.set(state, key, payload.value);
        }
        return new Promise((resolve) => {
            sys.setTimeout(() => {
                resolve([old, payload.value, key, state]);
            });
        });
    },
};
