import devapis from "./dev.endpoints";
import stagingapis from "./staging.endpoints";
import prodapis from "./prod.endpoints";
const env = a824.env();
export const endpoints = {
    "dev": {
        "base": env.APP_URL,
        "api_base": env.API_BASE,
        "static_token": env.STATIC_TOKEN,
        ...devapis
    },
    "staging": {},
    "production": {}
}

export default endpoints;