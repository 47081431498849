<template>
    <div class="dark:bg-slate-900 w-full">
      <div v-for="(item, index) in items" :key="index" class="mb-2">
        <!-- Accordion header -->
        <div
          class="flex justify-between items-center px-4 py-2 cursor-pointer border border-1"
          @click="toggle(index)"
        >
          <slot :name="'title'+getName(item)" :item="item" ></slot>
          <i :class="['fas', isActive(index) ? 'fa-chevron-down' : 'fa-chevron-left', 'transition-transform']"></i>
        </div>
        <!-- Accordion content -->
        <div v-if="renderType=='absolute'&&isActive(index)" class="dark:bg-gray-800">
          <div class="p-4">
            <slot :name="'content'+getName(item)" :item="item" ></slot>
          </div>
        </div>
        <div v-else-if="renderType!='absolute'">
          <div :class="{
            'p-4': true,
            'hidden': !isActive(index)
          }">
            <slot :name="'content'+getName(item)" :item="item" ></slot>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props:{
      renderType: {
        type:String,
        default:"absolute"
      },
      items:{
        type: Array,
        default:()=>([{title:"Accordian Title",content:"",name:""}])
      }
    },
    name: 'AppAccordion',
    data() {
      return {
        activeIndex: null
      };
    },
    methods: {
      getName(item) {
        return item.name?`_${item.name}`:'';
      },
      toggle(index) {
        this.activeIndex = this.activeIndex === index ? null : index;
      },
      isActive(index) {
        return this.activeIndex === index;
      },
    },
  };
  </script>