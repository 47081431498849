<template>
    <div class="app-markdown w-full dark:text-white dark:bg-gray-800" >
        <AppTab :tabs="tabs" v-model="tab" type="content"></AppTab>
        <div v-if="tab.key == 'code'">
            <textarea
                :value="markdown" 
                @input="(event)=>handleMarkdownChange(event.target.value)"
                class="w-full dark:text-white dark:bg-gray-800" 
                >
            </textarea>
            <textarea :name="name" class="hidden" :value="frameCode"  ></textarea>
        </div>
        <div v-else>
            <iframe :srcdoc="frameCode" class="bg-white resize"></iframe>
        </div>
    </div>
</template>
<script>
export default {
    inheritAttrs: false,
    props: {
        name: { type: String, default: "name" },
        modelValue: { type: [Array,String,Number], default: "" }
    },
    emits: ['update:modelValue'],
    data() {
        return {
            tab: { key: "code", value: "Code" },
            tabs: [
                { key: "code", value: "Code" },
                { key: "preview", value: "Preview" },
            ],
            markdown:this.modelValue,
        };
    },
    mounted() {
        this.$el.vnode = this;
        // window.AppMarkdown = this;
    },
    methods:{
        handleMarkdownChange(value) {
            this.markdown = value;
        }
    },
    computed: {
        frameCode() {
            return `
            <html>
                <head>
                    <style type="text/css">
                        body { font-family: 'Arial', sans-serif; max-width: 600px; margin: auto; }
                        .banner-image { width: 100%; height: auto; }
                        .content { padding: 20px; }
                        .footer { background-color: #f2f2f2; padding: 10px; text-align: center; }
                        .copyright { font-size: 12px; }
                    </style>
                </head>
                <body>
                    ${this.$markdown.render(this.markdown)}
                </body>
            </html>
            `;
        }
    }
};
</script>

<style>
    .app-markdown iframe {
        width: 100%;
    }
</style>