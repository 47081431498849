Number.prototype.toFill = function(place){
    let arr =  [];
    let temp = this.toString().split('').reverse();
    if(temp.length<place) {
        arr.length = place;
        arr = arr.fill('0').map((v,i)=>temp[i]||v).reverse();
        return arr.join('');
    } else {
        return this;
    }
}

Number.prototype.file = function(prepath=window.location.origin) {
    return `${prepath}/api/storage/files/${this}`.access();
}