import { createRouter, createWebHistory } from 'vue-router';
const pages = import.meta.glob('../pages/**/*.vue');

const routes = Object.keys(pages).map((key)=>({
    name:key.filename(),
    path: key.split("../pages/").join("/").split(".vue").join(''),
    component: pages[key]
}));
console.log({pages,routes});
const router = createRouter({
    history: createWebHistory(),
    routes:[
        {
            name:"default",
            path: "/",
            component:routes.find(o=>o.name=="home").component
        },
        ...routes
    ]
    //mode: "history" 
});

export default router;