import moment from "moment";
String.prototype.moment = Number.prototype.moment = Date.prototype.moment = function () {
    return moment(this);
};

Date.prototype.addDays = function (days = 1) {
    let startDate = moment(this).clone().add(days, "days");
    return new Date(startDate);
};

Date.prototype.firstDate = function () {
    const startOfMonth = moment(this).startOf("month").format("YYYY-MM-DD");
    return new Date(startOfMonth);
};

Date.prototype.lastDate = function () {
    const endOfMonth = moment(this).endOf("month").format("YYYY-MM-DD");
    return new Date(endOfMonth);
};

Date.prototype.toDate = function (sep = "-") {
    return moment(this).format(`YYYY${sep}MM${sep}DD`);
};

Date.prototype.toTime = function (sep = ":") {
    return moment(this).format(`HH${sep}mm${sep}ss`);
};
Date.prototype.toTimems = function (sep = ":") {
    return moment(this).format(`HH${sep}mm${sep}ss.S`);
};


Number.prototype.duration = function () {
    return moment.duration(this);
};

Date.prototype.diff = function(date,type="seconds") {
    return this.moment().diff(date,type);
}

String.prototype.moment = Number.prototype.moment = function() {
    return moment(this);
}

Number.prototype.duration = function() {
    return moment.duration(this);
}

Date.prototype.dateList = function() {
    let date = this.firstDate(),arr = [];
    while(date<date.lastDate()) {
        arr.push(date);
        date = date.addDays();
    }
    return [this.firstDate()].concat(arr);
};

Date.prototype.elapsedTime = function (now=new Date(),trunk=2) {
    let date = this;
    let timestamp = date.toDateTime().moment();
    let diff = timestamp.diff(now.getTime().moment());
    let duration = Math.abs(diff).duration();
    let arr = [
        {
            name: "years",
            short: "Y",
            value: duration.years(),
        },
        {
            name: "months",
            short: "M",
            value: duration.months(),
        },
        {
            name: "days",
            short: "d",
            value: duration.days(),
        },
        {
            name: "hours",
            short: "h",
            value: duration.hours(),
        },
        {
            name: "minutes",
            short: "mi",
            value: duration.minutes(),
        },
        {
            name: "seconds",
            short: "s",
            value: duration.seconds(),
        },
    ];
    return arr.filter(o=>o.value).map(o=>o.value+o.short).splice(0,trunk||arr.length).join(' , ')+' '+(diff>0?'ahead':'ago');
};

Date.prototype.calender = function () {
    let first_date = this.firstDate();
    let last_date = this.lastDate();
    let list = first_date.dateList();
    let fpos = first_date.getDay();
    let lpos = last_date.getDay() || 6;
    let first = []
        .filllen(fpos, new Date(this).firstDate())
        .map((o, i) => new Date(o).addDays(-(i + 1)));
    let last = []
        .filllen(lpos, new Date(this).lastDate())
        .map((o, i) => new Date(o).addDays(i + 1));
    return [].concat(first).concat(list).concat(last).chunk(7);
}



Date.prototype.toDateTime = function () {
    let date = this;
    return `${date.toDate()}T${date.toTime()}`;
};

Date.prototype.toDateTimeMs = function () {
    let date = this;
    return `${date.toDate()} ${date.toTimems()}`;
};

String.prototype.date = Number.prototype.date = function (pre = "", post = "") {
    return new Date(pre + this + post);
};

Date.prototype.nextSec = function (diff = 1) {
    return new Date(
        this.getFullYear(),
        this.getMonth(),
        this.getDate(),
        this.getHours(),
        this.getMinutes(),
        this.getSeconds() + diff
    );
};

String.prototype.time2date = function (diff = 1) {
    return new Date(new Date().toDate() + "T" + this);
};
