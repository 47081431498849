<template>
    <form @submit.stop="handleSubmit" class="flex flex-col">
        <div class="header" name="header"></div>
        <div class="form-contnent grow">
            <slot></slot>
        </div>
        <div class="footer">
            <slot name="footer"></slot>
        </div>
    </form>
</template>
<script>
export default {
    methods: {
        handleSubmit($event) {
            $event.stopImmediatePropagation();
            $event.preventDefault();
            this.$emit("update:modelValue", $event.target.getData());
            this.$emit("submit", $event.target.getData(),$event.target);
        }
    }
}
</script>
<style ></style>