<template>
    <div class="AppCodeEditor" :style="cssVars" :inputHeight="inputHeight">
        <template v-if="codeType == 'html'">
            <AppTab :tabs="tabs" v-model="tab" type="content"></AppTab>
            <div v-if="tab.key == 'code'">
                <Codemirror
                    ref="coderef"
                    :modelValue="modelValue"
                    :style="{ height: '400px' }"
                    :autofocus="true"
                    :indent-with-tab="true"
                    :tab-size="2"
                    :extensions="extensions"
                    :editorOptions="{
                        mode: 'htmlmixed',
                    }"
                    v-bind="$attrs"
                    @update:modelValue="
                        (value) => $emit('update:modelValue', value)
                    "
                    @ready="editorReady"
                />
            </div>
            <div v-else>
                <iframe :srcdoc="frameCode" class="bg-white resize"></iframe>
            </div>
        </template>
        <template v-else>
            <Codemirror
                ref="coderef"
                :modelValue="modelValue"
                :style="{ height: '400px' }"
                :autofocus="true"
                :indent-with-tab="true"
                :tab-size="2"
                :extensions="extensions"
                :editorOptions="{
                    mode: 'htmlmixed',
                }"
                v-bind="$attrs"
                @change="handleChange($event,'change')"
                @update:modelValue="handleChange($event,'modelValue')"
                @ready="editorReady"
            />
            <input type="hidden" :name="$attrs.name" :value="changedValue" >
        </template>
    </div>
</template>

<script>
import { javascript } from "@codemirror/lang-javascript";
import { html } from "@codemirror/lang-html";
import { json } from "@codemirror/lang-json";
import { oneDark } from "@codemirror/theme-one-dark";
export default {
    inheritAttrs: false,
    props: {
        modelValue: { type: [String], default: "" },
        codeType: { type: String, default: "javascript" },
        inputHeight: { type: [String, Number], default: null }
    },
    data() {
        return {
            tab: null,
            tabs: [
                { key: "code", value: "Code" },
                { key: "preview", value: "Preview" },
            ],
            changedValue:this.modelValue
        };
    },
    beforeMount() {
        window.AppCodeEditor = this;
        this.tab = this.tabs[0];
    },
    mounted(){
        this.$el.vnode = this;
        this.changedValue = this.modelValue;
    },
    methods: {
        handleChange(value,from) {
            this.changedValue = value;
            console.log("handleChange",{value,from});
            if(['javascript'].includes(this.codeType)) {
                this.$emit('update:modelValue', value);
            } else {
                this.$emit('update:modelValue', value);
            }
        },
        editorReady(...args) {
            console.log("editorReady",...args);
        }
    },
    computed: {
        frameCode() {
            return `<html>
            <head></head>
            <body style="margin:0px;padding:0px;">
                <b>${this.modelValue}</b>
            </body>
            </html>`;
        },
        extensions() {
            return [
                this.codeType == "javascript" ? [javascript()] : [],
                this.codeType == "html" ? [html()] : [],
                this.codeType == "json" ? [json()] : [],
                oneDark,
            ];
        },
        cssVars() {
            let cssVars = {};
            if (![null, undefined].includes(this.inputHeight)) {
                cssVars['--editor-height'] = this.inputHeight;
            }
            return cssVars;
        }
    },
    watch: {
        modelValue(nval,oval) {
            this.changedValue = nval;
            console.log("AppCodeEditor",{nval,oval});
        }
    }
};
</script>

<style lang="scss">
.AppCodeEditor {
    width: 100%;
    iframe > html > body {
        margin: 0;
    }
}

.AppCodeEditor[inputHeight] {
    height: var(--editor-height);

    .v-codemirror {
        height:  inherit;
        .cm-editor {
            height:  inherit;
        }
    }
}
</style>
