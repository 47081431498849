<template>
    <div class="w-full">
        <b>Options</b>
        <input type="hidden" name="options" v-model="stringifiedOptions" />
        <div class="flex flex-wrap items-center">
            <div v-for="opt in getAnswerOptions" class="w-1/2 md:w-1/3 p-1">
                <span
                    class="float-right pr-2 cursor-pointer"
                    @click="removeOption(opt.key)">
                    <i class="fa-solid fa-xmark"></i>
                </span>
                <AppInput
                    :label="'Option ' + opt.key"
                    type="text"
                    v-model="opt.value"
                    :required="true"
                    :placeholder="'Enter option ' + opt.key + ' value'"
                />
            </div>
            <div class="w-full md:w-1/4">
                <AppButton type="button" size="small" @click="addMoreOptions" class="mt-3">
                    Add option <em class="fa fa-plus"></em>
                </AppButton>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        options: {
            type: Array
        }
    },
    data() {
        return {
            default_options: [{'key': 'A', 'value': ''}],
            _options: []
        };
    },
    mounted() {
        window.QuizQuestionOptions = this;
        this._options = this.options.length ? this.options : this.default_options;
    },
    methods: {
        addMoreOptions() {
            const lastOption = this._options.slice(-1);
            const lastOptionKey = lastOption?.first()?.key;
            const newOptionKey = lastOptionKey
                ? String.fromCharCode(lastOptionKey.charCodeAt(0) + 1)
                : 'A';

            this._options.push({'key': newOptionKey, 'value': ''});
        },
        removeOption(optionKey) {
            this._options = this._options.filter((o) => o.key !== optionKey);
            let optKey = 'A';
            this._options = this._options.map((o) => {
                o.key = optKey;
                optKey = String.fromCharCode(optKey.charCodeAt(0) + 1);
                return o;
            });
        }
    },
    computed: {
        getAnswerOptions() {
            return this._options;
        },
        stringifiedOptions() {
            return JSON.stringify(this._options);
        }
    }
};
</script>
  
<style scoped>
</style>