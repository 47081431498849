import { mapActions, mapMutations, mapState } from 'vuex';
import layouts  from '../layouts';
import partials from '../components/partials';
import { api, apis } from '../apis/api';

import { Codemirror } from 'vue-codemirror';
import markdownIt from 'markdown-it';
import markdownItAttrs from 'markdown-it-attrs';
const markdown = markdownIt();
markdown.use(markdownItAttrs,{
    leftDelimiter: 'md:{',
    rightDelimiter: '}',
});

const LayoutComponents = layouts.reduce((collector,{name,component})=>({...collector,[name]:component}),{});
const PartialComponents = partials.reduce((collector,{name,component})=>({...collector,[name]:component}),{});
console.log({LayoutComponents});
export default {
    components: {
        ...PartialComponents,
        ...LayoutComponents ,
        Codemirror 
    },
    methods: {
        ...mapMutations(["setStore","setMutation"]),
        ...apis,
        log:console.log,
        alert(...args) {
            return alert(...args)
        },
        $gset(name,value) {
            let resolve = (...args)=>('');
            let promise = new Promise((r)=>{resolve=r});
            this.setStore({name,value});
            this.$nextTick(()=>{
                resolve({name,value});
            });
            return promise;
        },
        debounce(delay=500,callback=()=>('')) {
            return new Promise((resolve)=>{
                if(window.debouncest) {
                    clearTimeout(window.debouncest);
                }
                window.debouncest = setTimeout(()=>{
                    callback();
                    resolve();
                },delay);
            });
        },
        generateString(length) {
            const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789@#';
            let password = '';
            for (let i = 0; i < length; i++) {
              const randomIndex = Math.floor(Math.random() * charset.length);
              password += charset.charAt(randomIndex);
            }
            return password;
        }
    },
    computed: {
        $markdown(){
            return markdown;
        },
        $server(){
            return server.decode().parse();
        },
        api(){
            return api;
        },
        $state() {
            return this.$store.state;
        },
        layouts:()=>layouts.map(o=>o.layout).filter(v=>v).unique(),
        layout() {
            return this.$route.path.split('/').length>=3?this.$route.path.basename():'open'
        },
        inslots() {
            return this.$slots;
        },
        slotkeys() {
            return Object.keys(this.inslots);
        },
        urlparams(){
            return window.location.href.url().params;
        }
    }
}