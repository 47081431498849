<template lang="">
    <nav
        class="navbar dark:text-white top-0 left-0 right-0 fixed flex h-14 border-gray-100 z-30 w-screen transition-position m:pl-60 lg:w-auto lg:items-stretch dark:border-gray-800 ml-20 m:ml-0 backdrop-blur-sm backdrop-brightness-150 dark:backdrop-brightness-50 border-b"
    >
        <div
            class="absolute w-screen dark:bg-gray-900 bg-slate-100 top-14 left-0 shadow lg:w-auto lg:items-stretch lg:flex lg:grow lg:static lg:overflow-visible lg:shadow-none hidden backdrop-blur-sm backdrop-brightness-150 dark:backdrop-brightness-50 lg:border-b-0"
        >
            <div
                class="max-h-screen-menu overflow-y-auto lg:overflow-visible lg:flex lg:items-stretch lg:justify-end lg:mr-auto"
            ></div>
            <div
                class="max-h-screen-menu overflow-y-auto lg:overflow-visible lg:flex lg:items-stretch lg:justify-end lg:mx-auto"
            ></div>
            <div
                class="max-h-screen-menu overflow-y-auto lg:overflow-visible lg:flex lg:items-stretch lg:justify-end lg:ml-auto"
            >
                <div
                    class="block items-center grow-0 shrink-0 relative cursor-pointer hover:text-black dark:hover:text-white lg:flex p-0 lg:py-2 lg:px-3 lg:border-r lg:border-gray-100 lg:dark:border-gray-800"
                >
                    <AppSwitch
                        :modelValue="$state.darkmode"
                        onLabel="Dark"
                        offLabel="Light"
                        offIcon="fa fa-sun"
                        onIcon="fa fa-moon"
                        @update:modelValue="(val) => $gset('darkmode', val)"
                    />
                </div>
                <div
                    class="block items-center grow-0 shrink-0 relative cursor-pointer hover:text-black dark:hover:text-white lg:flex p-0 lg:py-2 lg:px-3 lg:border-r lg:border-gray-100 lg:dark:border-gray-800"
                >
                    <AppDropDown :options="profileMenu" >
                        <template #label>
                            <span class="w-6 h-6 mr-3 inline-flex align-bottom">
                                <img
                                    src="https://ui-avatars.com/api/?name=a&amp;color=7F9CF5&amp;background=EBF4FF"
                                    alt="admin"
                                    class="rounded-full block h-auto w-full max-w-full bg-gray-100 dark:bg-gray-800"
                                />
                            </span>
                            <span>Admin</span>
                        </template>
                    </AppDropDown>
                </div>
                <div class="w-8"></div>
            </div>
        </div>
    </nav>
</template>
<script>
export default {
    data() {
        return {
            profileMenu: [
                {
                    label: "MyAccount",
                    icon: "fa fa-user",
                    link: "/my-profile",
                },
                {
                    label: "Settings",
                    link: "/my-profile",
                },
                {
                    label: "Logout",
                    icon: "fa fa-right-from-bracket",
                    link: "/admin/logout",
                },
            ],
        };
    },
};
</script>
<style lang="scss">
.navbar {
    transition: margin-left 0.2s;
}
</style>
