<template>
    <canvas :id="id" :ref="id"></canvas>
</template>
  
<script>
import Chart from 'chart.js/auto';

export default {
    props: {
        id: {
            type: String,
            default: 'chart-' + Math.random().toString().slice(2)
        },
        data: {
            type: Array,
            required: true
        },
        title: {
            type: String,
            required: true
        },
        type: {
            type: String,
            default: 'bar'
        },
        indicator: {
            type: String,
            default: 'Count'
        }
    },
    data() {
        return {
            chart: null,
            labels: [],
            dataValues: []
        }
    },
    mounted() {
        this.renderChart();
        window.AppChart = this;
    },
    methods: {
        renderChart() {
            if (this.chart) {
                try {
                    this.chart.destroy();
                } catch (error) {
                    console.log("Error on Destroy of Chart", error);
                }
            }

            if (!this.data.length) {
                return;
            }

            this.chart = new Chart(this.id, {
                type: this.type,
                data: this.chartData,
                options: {
                    plugins: {
                        title: {
                            display: true,
                            text: this.title
                        }
                    }
                }
            });
        },
        getChartLabels() {
            return (this.data || []).map(o => o.label);
        }
    },
    computed: {
        chartData() {
            return {
                labels: (this.data || []).map(o => o.label),
                datasets: [{
                    label: this.indicator,
                    data: (this.data || []).map(o => o.value)
                }]
            }
        }
    },
    watch: {
        data() {
            this.renderChart();
        }
    }
};
</script>
  
<style scoped></style>