<template>
    <div class="inline-block" v-bind="$attrs">
        <div class="flex gap-2 m-auto">
            <slot name="tableft"></slot>
            <div class="AppTab tab flex">
                <div
                    v-for="(tab, i) in tabs"
                    :key="i"
                    :class="
                        Object.className({
                            [reverse?activecolor:inactivecolor]:!active(
                                    (tab[setid] || tab),
                                    islink ? currentpath : (selectedtab[setid]||selectedtab),
                                    islink,
                                    tab
                                ),
                            'px-4 py-2 text-center': true,
                            [ (reverse?inactivecolor:activecolor)+' border-b-2 border-gray-100']:
                                active(
                                    (tab[setid] || tab),
                                    islink ? currentpath : (selectedtab[setid]||selectedtab),
                                    islink,
                                    tab
                                ),
                            'cursor-pointer': !islink,
                            [tabclass || '']: true,
                        })
                    "
                    @click="setTab(tab)"
                >
                    <span v-if="islink">
                        <a :href="tab[setid] || tab">
                            {{ (tab[name] || tab).toTitleCase() }}
                        </a>
                    </span>
                    <span v-else>
                        {{ (tab[name] || tab).toTitleCase() }}
                    </span>
                </div>
            </div>
            <slot name="tabright"></slot>
        </div>
    </div>
    <slot :name="(selectedtab[setid]||selectedtab)"></slot>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
    inheritAttrs: false,
    emits: ["update:modelValue"],
    props: {
        activecolor:{type:String, default:"bg-gray-100 dark:bg-gray-900"},
        inactivecolor:{type:String, default:"bg-white dark:bg-gray-800"},
        reverse:{type: Boolean, default: false },
        tabs: { type: Array, default: () => [] },
        name: { type: String, default: "label" },
        setid: { type: String, default: "value" },
        modelValue: { type: [String, Number, Object, Array], default: "" },
        type: { type: String, default: "link" },
        active: {
            type: Function,
            default: (taburl, currentpath, islink) =>
                islink
                    ? currentpath.pathname.startsWith(taburl.url().pathname)
                    : taburl == currentpath,
        },
        tabclass: { type: String, default: "" },
    },
    data() {
        return { selectedtab: this.modelValue };
    },
    methods: {
        setTab(tab) {
            this.selectedtab = tab;
            this.$emit("update:modelValue", tab);
        },
    },
    watch: {
        modelValue(value) {
            let tab = this.tabs.find(o=>((o[this.setid]||o)==(value[this.setid]||value)));
            this.selectedtab = tab;
        }
    },
    computed: {
        islink() {
            return this.type == "link";
        },
        currentpath() {
            return (window.location.origin + this.$page.url).url();
        },
    },
    mounted() {
        window.AppTab = this;
    }
});
</script>

<style></style>
