export default [
    {
        path: '/admin/manage-users',
        name: 'manage-users',
        label:"Manage Users",
        icon: 'fa fa-business-time',
        scope: ["admin"]
    },
    {
        path: '/admin/manage-schema_collectors',
        name: 'manage-schema_collectors',
        label:"Manage Collectors",
        icon: 'fa fa-business-time',
        scope: ["admin"]
    },
    {
        path: '/admin/manage-quiz_episodes',
        name: 'manage-quiz_episodes',
        label:"Manage Quiz Episodes",
        icon: 'fa fa-business-time',
        scope: ["admin"]
    },
    {
        path: '/admin/manage-quiz_questions',
        name: 'manage-quiz_questions',
        label:"Manage Quiz Questions",
        icon: 'fa fa-business-time',
        scope: ["admin"]
    }
]