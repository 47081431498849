/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import './bootstrap';
import { createApp } from 'vue';
import { store } from './store';
import { routes } from './routes';
import App from './components/App.vue';
import mixins from './mixins/mixins';


console.log({mixins,server});

/**
 * Next, we will create a fresh Vue application instance. You may then begin
 * registering components with the application instance so they are ready
 * to use in your application's views. An example is included for you.
 */

const app = createApp(App);
console.log({routes});
app.config.compilerOptions.isCustomElement = tag => tag.startsWith('App');
app.use(store);
app.use(routes);
app.mixin(mixins);

window.app = app;

// import App from './components/App.vue';
// app.component('vite-app', App);

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// Object.entries(import.meta.glob('./**/*.vue', { eager: true })).forEach(([path, definition]) => {
//     app.component(path.split('/').pop().replace(/\.\w+$/, ''), definition.default);
// });

/**
 * Finally, we will attach the application instance to a HTML element with
 * an "id" attribute of "app". This element is included with the "auth"
 * scaffolding. Otherwise, you will need to add an element yourself.
 */

app.mount('#app');

document.addEventListener('contextmenu', function(e) {
    e.preventDefault();
});
document.addEventListener('keydown', function(e) {
    console.log({code:e.code});
    if (e.code === 'PrintScreen') {
        alert('Screenshots are not allowed!');
        // Or perform another action of your choice
    } else if (e.key === 'PrintScreen') {
        alert('Screenshots are not allowed!');
        // Or perform another action of your choice
    }
});
document.addEventListener('copy', function(e) {
    e.preventDefault();
});