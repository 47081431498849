<template>
    <div class="autocomplete-select">
      <div class="relative">
        <input
          type="search"
          :name="$attrs.name"
          :value="search"
          @input="handleInputChange"
          @focus="handleFocus"
          placeholder="Search..."
          class="bg-gray-100 w-full dark:bg-gray-800 rounded-md p-2 pl-8 pr-12 border focus:outline-none focus:ring focus:border-blue-300"
        />
        <div class="absolute top-0 left-0 flex items-center h-full ml-2">
          <em class="fa fa-search"></em>
        </div>
      </div>
      <ul v-if="showDropdown" class="mt-2 bg-white dark:bg-gray-800 border border-gray-300 rounded-md shadow-md w-full max-h-32 overflow-y-auto">
        <li
          v-for="(item, index) in filteredItems"
          :key="index"
          @click="selectItem(item)"
          class="px-4 py-2 cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-900"
        >
          <template v-if="slotkeys.includes('option')">
            <slot name="option" :option="item" :index="index"></slot>
          </template>
          <template v-else>
            {{ item.label }}
          </template>
        </li>
      </ul>
    </div>
  </template>
  
  <script>
  export default {
    inheritAttrs: false,
    props: {
      modelValue: { type: String, default: "" },
      options:{
        type: Array,
        default: ()=>([])
      }
    },
    data() {
      return {
        focus: false,
        search:this.modelValue,
        ioptions:this.options,
        selected: ''
        // Replace with your data
      };
    },
    computed: {
      showDropdown() {
        return this.filteredItems.length > 0 && (this.focus || this.search.length > 0);
      },
      filteredItems() {
        if(this.search) {
          return this.ioptions.filter(item =>
            item.label.toLowerCase().includes((this.search + '').toLowerCase())
          );
        } else {
          return this.ioptions;
        }
      },
    },
    watch: {
      focus(val) {
        if(val) {
          this.fetchOptions();
        }
      }
    },
    mounted() {
      window.AppAutocomplete = this;
    },
    methods: {
      handleFocus() {
        this.focus = true;
      },
      handleBlur() {
        setTimeout(()=>{
          console.log("handleBlur");
          this.focus = false;
        },1000);
      },
      fetchOptions() {
        if(this.$attrs.refrences) {
          this.apiCurd("Dynamic",{
            action:"list",
            tablename:this.$attrs.refrences,
            [this.$attrs.refrence_value]:this.search
          }).then(({data})=>{
            this.ioptions = data.options();
          });
        }
      },
      handleInputChange(e) {
        this.search = e.target.value;
        if(this.search != this.selected) {
          this.fetchOptions();
        }
      },
      filterItems() {
        // Implement filtering logic here
      },
      selectItem(item) {
        console.log({item});
        this.search = ''+item.value;
        this.selected = ''+item.value;
        this.$emit("update:modelValue", this.selected);
        this.focus = false;
        // Handle item selection here
      },
    },
    mounted() {
      window.AppAutocomplete = this;
    }
  };
  </script>
  
  <style >
    .autocomplete-select {
      width: 100%;
    }
  </style>
  