<template>
    <div class="mb-6 last:mb-0">
        <label v-if="label" class="block font-bold mb-2">{{ label }}</label>
        <div class="grid grid-cols-1 gap-3">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        label: {
            type: String,
            default: "",
        },
    }
};
</script>

<style></style>
