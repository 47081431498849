<template lang="">
    <button type="button" class="bg-blue-500 px-4 py-2 rounded-sm text-white" v-bind="$attrs" >
        <slot></slot>
    </button>
</template>
<script>
export default {
    
}
</script>
<style lang="">
    
</style>