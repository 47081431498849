<template lang="">
    <div class="h-screen w-screen flex justify-center items-center dark:bg-gray-800  bg-gray-200">
        <router-view></router-view>
        <slot></slot>
    </div>
</template>
<script>
export default {
    
}
</script>
<style lang="">
    
</style>