const constantAttributes = {
    setTimeout:(...arg)=>{
        if(arg.length==2 && typeof arg[0] === "function" && typeof arg[1] == "number") {
            return setTimeout(...arg);
        }
    },
    setInterval:(...arg)=>{
        if(arg.length==2 && typeof arg[0] === "function" && typeof arg[1] == "number") {
            return setInterval(...arg);
        }
    },
    eval:(...arg)=>{
        return eval(...arg);
    }
}
Object.freeze(constantAttributes);
window.sys = constantAttributes;