<template>
    <button class="flex items-center cursor-pointer w-full" type="button" @click="toggleSwitch" :disabled="$attrs.disabled">
        <!-- Icon when the switch is off -->
        <i v-if="!isChecked" :class="{'mr-2 ml-auto my-auto': true,[offIcon]:true}"></i>
        <!-- Icon when the switch is on -->
        <i v-else :class="{'mr-2 ml-auto my-auto': true,[onIcon]:true}"></i>
        <!-- Actual switch input -->
        <input type="checkbox" class="hidden" :name="$attrs.name" :checked="isChecked" />
        <!-- Text label -->
        <span class="mr-auto">{{ isChecked ? onLabel : offLabel }}</span>
    </button>
</template>

<script>
export default {
    inheritAttrs: false,
    props: {
        onIcon: {
            type:String,
            default: "fas fa-toggle-on text-green-500 text-4xl"
        },
        offIcon: {
            type:String,
            default: "fas fa-toggle-off text-red-500 text-4xl "
        },
        onLabel:{ type: String, default:"ON"},
        offLabel:{ type: String, default:"OFF"},
        modelValue: { type: [Boolean,Number], default: undefined },
        defaultValue: { type: [Boolean,Number], default: false }
    },
    data() {
        return {
            isChecked: this.modelValue,
        };
    },
    mounted() {
        window.AppSwitch = this;
    },
    methods: {
        toggleSwitch() {
            this.isChecked = !this.isChecked;
            this.isChecked = this.isChecked?1:0;
            this.$emit("update:modelValue",this.isChecked);
        },
    },
    watch: {
        modelValue(val) {
            if (val != this.isChecked) {
                this.isChecked = val;
            }
        },
        defaultValue: {
            immediate: true,
            handler:function (val) {
                if(val!==undefined && val!==this.modelValue && this.modelValue === undefined) {
                    this.$emit("update:modelValue",val);
                }
            }
        }
    }
};
</script>
  
<style scoped>
button[disabled] {
    cursor: no-drop;
}
</style>