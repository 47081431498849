import { createStore } from 'vuex';
import mutations from "./mutations";
import state from "./state";
import "./security";

const linkLocalStorage = (config={key:"root"})=>{
    const storage = window?.localStorage||globalThis?.localStorage;
    return (store)=>{
        let savedState = JSON.parse(storage[config.key]||"{}");
        let emptyLocal = !Object.keys(savedState).length;
        savedState = emptyLocal?store.state:savedState;
        store.replaceState(savedState);
        if(emptyLocal) {
            storage.setItem(config.key,JSON.stringify(savedState));
        }
        store.subscribe((mutation, state)=>{
            console.log({mutation,state});
            storage.setItem(config.key,JSON.stringify(state));
        });
    }
}

export default createStore({
    plugins: [linkLocalStorage()],
    state,
    mutations,
    actions:{}
});