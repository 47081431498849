<template>
	<div class="AppTable table-auto w-full mx-auto">
		<div class="flex gap-2">
			<button class="first:ml-auto" type="button" size="small" v-if="this.selectedData.length"
				@click="deleteSelected">
				Delete
			</button>
		</div>
		<table class="max-w-full w-full" :class="{ 'border-separate border-spacing-y-1': singlecol }">
			<caption></caption>
			<thead>
				<tr>
					<th :colspan="colspan" class="px-0 pb-5" scope="configs">
						<div class="flex relative">
							<span class="mr-auto">
								<div class="flex">
									<slot name="left-perpage"></slot>
									<span class="relative">
										<span class="whitespace-nowrap absolute -bottom-5 w-full text-center text-sm">
											Per Page
										</span>
										<input min="0" v-model.lazy="inperpage" type="number"
											:class="classes.input + ' w-[100px] text-center'"
											@change="() => debounce(500).then(() => onPageChange())" />
									</span>
									<span class="px-4" title="Reload Table">
										<button size="small" type="button" @click="() => onFetch()">
											<em :class="{ 'fa fa-refresh': true, 'animate-spin': $state.loading }" />
										</button>
									</span>
									<slot name="right-perpage"></slot>
								</div>
							</span>
							<span class="ml-auto flex gap-1" v-if="inperpage">
								<slot name="left-nav"></slot>
								<button type="button" size="small" class="h-max bg-blue-500 px-4 py-1 text-white"
									v-if="paginate.first && currentPage > 1" @click="firstRecornd">
									{{ "First" }}
								</button>
								<button type="button" size="small" class="h-max bg-blue-500 px-4 py-1 text-white"
									v-if="currentPage > 1" @click="prevRecornd">
									{{ "Prev" }}
								</button>
								<select v-if="paginate && paginate.list && paginate.list.length" v-model="currentPage"
									:class="classes.input + ' w-[100px] text-center pe-5 cursor-pointer'" @change="toPage">
									<option v-for="(page, i) in paginate.list" :value="page.pageno" :key="i">
										{{ page.name }}
									</option>
								</select>
								<span v-else class="px-5 hidden lg:inline"> [ Page {{ currentPage }}] </span>
								<button type="button" size="small" class="h-max bg-blue-500 px-4 py-1 text-white"
									v-if="data.length && data.length >= inperpage" @click="nextRecord">
									{{ "Next" }}
								</button>
								<button type="button" size="small" class="h-max bg-blue-500 px-4 py-1 text-white"
									v-if="paginate.last && data.length && data.length >= inperpage" @click="lastRecord">
									{{ "last" }}
								</button>
								<span class="px-5" v-if="data.length">{{ data.length }} {{ data.length == 1 ? "row" : "rows"
								}}</span>
								<slot name="right-nav"></slot>
							</span>
						</div>
					</th>
				</tr>
				<tr v-if="!singlecol" class="hidden lg:table-row dark:bg-gray-800 bg-gray-100 divide-x divide-slate-400/25">
					<th v-if="selectable" :class="classes.check_max_w + ' whitespace-nowrap'" scope="checklable">
						Select
					</th>
					<th v-if="hasDetailed" scope="detailslable">##</th>
					<th scope="sorting" v-for="(key, i) in cols" :key="i" :class="
						Object.className({
							'whitespace-nowrap': true,
							'cursor-pointer': infields[key].sortable,
						})
					" @click="() => handleHeadClick(key, i)" v-bind="getField(key, 'th')">
						<div :class="Object.className({ flex: infields[key].sortable })">
							<span>
								{{ infields[key]?.label || key.toTitleCase() }}
							</span>
							<span v-if="infields[key].sortable && filter.sortfield == key">
								<span v-if="filter.sortdir == 'desc'" title="Sort Descending">
									<em class="fa fa-arrow-down"></em>
								</span>
								<span v-else-if="filter.sortdir == 'asc'" title="Sort Ascending">
									<em class="fa fa-arrow-up"></em>
								</span>
							</span>
						</div>
					</th>
					<th v-if="deletable || editable" scope="edit-delete"></th>
				</tr>
				<tr v-if="filters.length || selectable" class="dark:bg-gray-900 bg-gray-100">
					<th v-if="selectable" :class="classes.check_max_w" scope="checkbox">
						<input v-if="data.length" type="checkbox" :class="classes.input + ' cursor-pointer'"
							:checked="select_all" @change="(e) => toggleSelectAll(e.target.checked)" />
					</th>
					<th v-if="hasDetailed" scope="details"></th>
					<template v-if="singlecol">
						<th :colspan="colspan" class="px-1 pb-4">
							<div class="flex gap-1 relative">
								<span class="absolute left-1 h-full flex">
									<em class="fa fa-arrow-up m-auto" />
								</span>
								<input v-if="filters.find((o) => o[0] == cols[0])?.filter" v-model="filter[cols[0]]"
									type="search" class="min-w-[75px] grow" :class="
										classes.input
											.classlist({
												'px-2': false,
												'py-1': false,
												'h-8': false,
												'py-2': true,
												'pl-8': true,
												'pr-2': true,
												'text-sm': false,
											})
											.str()
									" placeholder="Search..." @change="() => onFetch()" />
								<button type="button" size="small" @click="() => onFetch()">go</button>
							</div>
						</th>
					</template>
					<template v-else>
						<template v-if="true">
							<th v-for="(key, i) in cols" :key="i" v-bind="getField(key, 'th')" class="hidden lg:table-cell">
								<template v-if="slotKeys.includes('filter-'+key)">
									<slot 
										:name="'filter-'+key" 
										:value="filter[key]" 
										:onChange="value=>handleFilterChange(filter,key,value)" 
										:filter="filter" 
										:key="key" 
										:classes="classes" 
										:onFetch="onFetch" 
										:isSelected="value=>filter[key]==value"
										>
									</slot>
								</template>
								<input v-else-if="filters.find((o) => o[0] == key)?.filter" v-model="filter[key]" type="search"
									class="min-w-[75px]" :class="classes.input" @change="() => onFetch()" />
							</th>
						</template>
						<template v-else>
							<th :colspan="cols.length + ((deletable || editable) ? 1 : 0)" class="lg:hidden">
								<div class="flex flex-wrap">
									<select v-model="selectedfilter">
										<option
											v-for="(key, i) in cols.filter(key => filters.find((o) => o[0] == key)?.filter)"
											:key="i" :value="key">
											{{ key }}
										</option>
									</select>
									<input v-if="filters.find((o) => o[0] == selectedfilter)?.filter"
										v-model="filter[selectedfilter]" type="search" class="min-w-[75px] w-auto"
										:class="classes.input" @change="() => onFetch()" />
								</div>
							</th>
						</template>
						<th v-if="deletable || editable" class="hidden lg:block"></th>
					</template>
				</tr>
			</thead>
			<tbody v-if="data.length">
				<template v-for="(obj, i) in data" :key="i + 'row'">
					<tr class="dark:bg-gray-900/50 bg-gray-100" :class="{ uniformbg: singlecol, [rowclass(obj)]: true }">
						<td v-if="selectable" :class="classes.check_max_w" style="width: 60px">
							<input type="checkbox" :class="classes.input + ' cursor-pointer'" v-model="selectedIndex[i]" />
						</td>
						<td v-if="hasDetailed" style="width: 30px">
							<em class="fa fa-angle-right cursor-pointer rotate-90" v-if="detailedArr[i]?.open"
								@click="toggleDetails(i)" />
							<em class="fa fa-angle-right cursor-pointer" v-else @click="toggleDetails(i)" />
						</td>
						<td style="width: 30px" v-else-if="singlecol">#</td>
						<template v-if="singlecol">
							<td :colspan="cols.length" :class="{ 'text-center': true, [colclass(obj)]: true }">
								<div v-if="slotKeys.includes(cols[0])">
									<slot :name="cols[0]"
										v-bind="{ value: this.getValue(cols[0], obj), col: cols[0], j, row: obj, node }">
									</slot>
								</div>
								<div v-else v-html="getRender(cols[0], obj, i, j)"></div>
							</td>
						</template>
						<template v-else>
							<td v-for="(col, j) in cols" :key="j" v-bind="getField(col, 'td')">
								<div class="inline-block md:hidden font-bold pr-2">{{ getField(col,
									'td').label || (col.toTitleCase()) }}</div>
								<div v-if="slotKeys.includes(col)" class="grow">
									<slot :name="col" v-bind="{ value: this.getValue(col, obj), col, j, row: obj, node }">
									</slot>
								</div>
								<div v-else v-html="getRender(col, obj, i, j)" class="grow"></div>
							</td>
						</template>
						<td v-if="deletable || editable || !!actions({ row: obj, i }).length" style="width: 20px">
							<AppDropDown triggerClass="flex">
								<template #trigger>
									<em class="fa fa-ellipsis-v cursor-pointer ml-auto" />
								</template>
								<template #content>
									<div class="">
										<div v-if="editable" :class="classes.action"
											@click="$emit('edit', { row: obj, i, filter: filter_payload })">
											Edit
										</div>
										<div v-if="deletable" :class="classes.action"
											@click="deleteRow({ row: obj, i, filter: filter_payload })">
											Delete
										</div>
										<div v-if="clonable" :class="classes.action"
											@click="$emit('clone', { row: obj, i, filter: filter_payload })">
											Clone
										</div>
										<template v-for="(group, name) in actions({ row: obj, i }).group()" :key="name">
											<div class="px-2 text-sm text-gray-500 border-t-2 border-gray-500">
												{{ name.toTitleCase() }}
											</div>
											<template v-for="(action, k) in group">
												<router-link :key="k + 'link' + name" v-if="action.path"
													:href="getActionPath({ action, row: obj, i })" :class="classes.action">
													{{ action.label }}
												</router-link>
												<div :key="k + 'dev' + name" v-else :class="classes.action"
													@click="emitAction({ action, row: obj, i })" v-html="action.label">
												</div>
											</template>
										</template>
										<slot name="actionlist"></slot>
									</div>
								</template>
							</AppDropDown>
						</td>
					</tr>
					<tr v-if="hasDetailed && detailedArr[i]?.open" :key="i + 'detailed'"
						class="dark:bg-gray-900 bg-slate-100 detailed">
						<td :colspan="colspan" class="">
							<div class="divide-y divide-slate-400/25">
								<div v-for="(key, k) in detailedKeys" :key="k" class="mb-4">
									<AppField :label="infields[key]?.label || key.toTitleCase()" :name="key">
										<div v-if="slotKeys.includes(key)" class="text-left text-slate-100">
											<slot :name="key" v-bind="{ value: obj[key], key, k, row: obj, node }"></slot>
										</div>
										<div v-else v-html="getRender(key, obj, i, k)"
											class="text-left dark:text-slate-200"></div>
									</AppField>
								</div>
							</div>
						</td>
					</tr>
				</template>
			</tbody>
			<tfoot>
				<slot name="foot" v-bind="{ colspan: (colspan), node, data }"></slot>
			</tfoot>
		</table>
		<div v-if="!data.length">No Results found for this program</div>
	</div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
	props: {
		name: {
			type: String,
			default: "",
		},
		data: {
			type: Array,
			default: () => [],
		},
		visible: {
			type: Array,
			default: () => [],
		},
		addcols: {
			type: Array,
			default: () => [],
		},
		except: {
			type: Array,
			default: () => [],
		},
		fields: {
			type: Object,
			default: () => ({}),
		},
		selectable: {
			type: Boolean,
			default: false,
		},
		editable: {
			type: Boolean,
			default: false,
		},
		deletable: {
			type: Boolean,
			default: false,
		},
		detailed: {
			type: Boolean,
			default: true,
		},
		singlecol: {
			type: Boolean,
			default: false,
		},
		clonable: {
			type: Boolean,
			default: false,
		},
		perpage: {
			type: Number,
			default: 10,
		},
		exportDetails: {
			type: Boolean,
			default: false,
		},
		onExportRow: {
			type: Function,
			default: (v) => v,
		},
		rowclass: {
			type: Function,
			default: (v) => v || "",
		},
		colclass: {
			type: Function,
			default: (v) => v || "",
		},
		paginate: {
			type: Object,
			default: () => ({}),
		},
		actionlist: {
			type: [Array, Function],
			default: () => [],
		}
	},
	components: {},
	data() {
		return {
			inperpage: this.perpage,
			filter: {
				sortdir: "",
				sortfield: "",
			},
			selectedfilter: "",
			selectedIndex: [],
			node: this,
			detailedArr: [],
			detailedIndex: 0,
			currentPage: 1,
		};
	},
	computed: {
		filter_payload() {
			return {
				offset: this.offset,
				limit: this.limit,
				...this.filter,
			};
		},
		limit() {
			return this.inperpage;
		},
		offset() {
			return this.inperpage * (this.currentPage - 1);
		},
		maxKeys() {
			return this.data.maxObjKeys().filter((key) => !this.except.includes(key));
		},
		detailedKeys() {
			return this.maxKeys.filter((key) => !this.cols.includes(key));
		},
		colspan() {
			let count = this.cols.length;
			if (this.selectable) {
				count++;
			}
			if (this.deletable || this.editable) {
				count++;
			}
			return count + 1;
		},
		hasDetailed() {
			return this.detailed && this.maxKeys.length > this.cols.length;
		},
		isDetailsOpened() {
			return this.hasDetailed && this.detailedArr[this.detailedIndex]?.open;
		},
		slotKeys() {
			return Object.keys(this.$slots);
		},
		classes() {
			return {
				check_max_w: "",
				action:
					"block px-4 py-2 text-sm cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700 dark:text-white text-gray-700 focus:bg-gray-100 transition",
				input:
					"px-2 py-1 text-sm max-w-full focus:ring focus:outline-none border-gray-700 rounded w-full dark:placeholder-gray-400 h-8 border bg-white dark:bg-gray-800",
				button:
					"inline-flex items-center border border-transparent rounded-md font-semibold text-xs uppercase tracking-widest disabled:opacity-25 transition bg-blue-800 hover:bg-indigo-700 active:bg-purple-900 focus:outline-none focus:border-indigo-900 focus:ring focus:ring-indigo-300 text-white",
			};
		},
		allcols() {
			return [].concat(this.visible, Object.keys(this.fields), this.maxKeys, this.addcols).unique();
		},
		cols() {
			let keys = this.allcols;
			let visible = this.visible.length ? this.visible : keys;
			let cols = keys.filter((k) => visible.includes(k) || this.addcols.includes(k));
			this.selectedfilter = this.selectedfilter || cols[0];
			return cols;
		},
		infields() {
			return this.allcols.reduce(
				(old, key) => ({
					...old,
					[key]: {
						...(this.fields[key] || { name: key, label: key.toTitleCase() }),
					},
				}),
				{}
			);
		},
		filters() {
			return Object.entries(this.infields).filter(([key, val]) => val.filter);
		},
		indexes() {
			return Object.entries(this.selectedIndex)
				.filter(([key, val]) => val)
				.map(([key, val]) => Number(key));
		},
		selectedWithKeys() {
			return this.data.filter((o, i) => this.indexes.includes(i));
		},
		selectedData() {
			return this.data
				.filter((o, i) => this.indexes.includes(i))
				.map((o) =>
					Object.filter(
						o,
						(v, k) =>
							this.cols.includes(k) || (this.exportDetails && this.detailedKeys.includes(k))
					)
				)
				.map((o) => this.onExportRow(Object.map(o, (v, k) => this.getValue(k, o))));
		},
		select_all() {
			return this.indexes.length == this.data.length;
		},
	},
	created() {
		this.setData();
	},
	mounted() {
		window.AppTable = this;
		this.onFetch();
	},
	methods: {
		handleFilterChange(filter,key,value) {
			filter[key] = value;
			this.onFetch();
		},
		getActionPath({ action, row, i }) {
			let path = Object.keys(row).reduce(
				(path, key) => decodeURIComponent(path).split(`{${key}}`).join(row[key]),
				action.path
			);
			path = Object.keys(this.urlparams).reduce(
				(path, key) => path.split(`{${key}}`).join(this.urlparams[key]),
				path
			);
			return path;
		},
		actions({ row, i }) {
			let list = [];
			if (Array.isArray(this.actionlist)) {
				list = this.actionlist;
			} else if (typeof this.actionlist == "function") {
				list = this.actionlist({ row, i });
			} else {
				list = [];
			}
			return list
				.filter((o) => o.type != "main")
				.filter((o) => () => {
					if (o.component === undefined) {
						return true;
					} else if (typeof o.component == "string") {
						return o.component == this.$page.component;
					} else if (Array.isArray(o.component)) {
						return o.component.includes(this.$page.component);
					} else {
						return false;
					}
				});
		},
		emitAction({ action, row, i }) { },
		toPage(value) {
			this.onFetch();
		},
		getField(col, name, def = {}) {
			let field = this.infields[col];
			let objfields = { ...field, ...(field[name] || {}) };
			return Object.only(objfields || def, ["class", "width"]);
		},
		onPageChange() {
			this.currentPage = 1;
			this.onFetch();
		},
		toggleSort(key) {
			if (this.filter.sortfield == key) {
				switch (this.filter.sortdir) {
					case "desc":
						this.filter.sortdir = "asc";
						break;
					case "asc":
						this.filter.sortdir = "";
						break;
					default:
						this.filter.sortdir = "desc";
				}
			} else {
				this.filter.sortfield = key;
				this.filter.sortdir = "desc";
			}
			this.onFetch();
		},
		handleHeadClick(key, i) {
			let obj = this.infields[key];
			if (obj.sortable) {
				this.toggleSort(key);
			}
		},
		nextRecord() {
			this.currentPage++;
			this.onFetch();
		},
		lastRecord() {
			this.currentPage = this.paginate.last.pageno;
			this.onFetch();
		},
		firstRecornd() {
			this.currentPage = this.paginate.first.pageno;
			this.onFetch();
		},
		prevRecornd() {
			this.currentPage > 1 && this.currentPage--;
			this.onFetch();
		},
		setData() {
			this.detailedArr = this.data.map(() => ({ open: false }));
		},
		toggleDetails(i) {
			this.detailedIndex = i;
			let val = Object.getNested(this.detailedArr, `${i}.open`, false);
			Object.setNested(this.detailedArr, `${i}.open`, !val);
		},
		deleteRow(payload) {
			confirm("Please confirm your action.").promise.then(() => {
				this.$emit("delete", payload);
			});
		},
		toggleSelectAll(checked) {
			if (checked) {
				Object.keys(this.data).map((index) => {
					this.selectedIndex[index] = true;
				});
			} else {
				Object.keys(this.data).map((index) => {
					this.selectedIndex[index] = false;
				});
			}
		},
		deleteSelected() {
			confirm("Please confirm your action.").promise.then(() => {
				this.$emit("delete-selected", this.selectedWithKeys);
			});
		},
		onFetch() {
			this.$emit("fetch", this.filter_payload, this);
		},
		getRender(col, obj) {
			let field = this.infields[col];
			let getRenderData = field?.getRender && field?.getRender(obj[col], obj, col);
			let data = getRenderData || this.getValue(col, obj);
			if (field.type == "file" && field.refrences == "files") {
				let href = `/storage/secure/image?fileId=${data}&rd=25`;
				data = `<a href="${href}" target="_blank" class="inline-block" >
						<span class='h-12 w-12 bg-contain bg-no-repeat inline-block rounded' style='background-image:url(${href + '&asimg=1'})' ></span>
					</a>`
			}
			return data;
		},
		defVal(v, a) {
			return v || a;
		},
		getValue(col, obj) {
			let field = this.infields[col];
			let getValueData = field?.getValue && field?.getValue(obj[col], obj, col);
			let data = getValueData || this.defVal(obj[col], "-");
			return data;
		},
	},
	watch: {
		perpage(val) {
			this.inperpage = val;
		},
	},
});
</script>

<style lang="scss">
.AppTable {
	table {
		overflow-wrap: break-word;
	}

	tbody tr.detailed td {
		background: initial;
	}

	tbody tr.detailed:hover td {
		background: initial;
	}
}
</style>
