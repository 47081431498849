<template>
    <div class="AppAlert">
        <div v-for="(obj, i) in alerts" :key="i"
            class="fixed top-0 left-0 bg-slate-100/75 dark:bg-gray-900/75 h-full w-full flex z-[50]">
            <div
                class="mx-auto my-auto min-h-[150px] min-w-[300px] bg-white dark:bg-slate-800 dark:text-white rounded px-5 py-5 relative flex flex-col">
                <em v-if="obj.closable" class="fa fa-close absolute top-5 right-5 cursor-pointer"
                    @click="close(false)"></em>
                <div v-if="obj.title" v-html="obj.title" class="text-center text-xl font-bold my-1"></div>
                <div v-if="typeof obj.text == 'string'" class="my-auto text-xl w-full text-center" v-html="obj.text"></div>
                <div class="flex my-1" v-if="obj.footer">
                    <button
                        class="ml-auto mr-1 px-5 py-2 rounded text-lg bg-red-400 text-white min-w-[120px] block text-center"
                        @click="close(false)">
                        {{ obj.cancel }}
                    </button>
                    <button class="ml-1 mr-auto px-5 py-2 rounded text-lg min-w-[120px] border block text-center"
                        @click="close(true)">
                        {{ obj.ok }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            alerts: [],
        };
    },

    methods: {
        getPayload(payload = {}) {
            let newobj = {
                type: "alert",
                title: "",
                footer: false,
                html: false,
                text: "",
                ok: "OK",
                cancel: "CANCEL",
                closable: true,
                close: (v) => this.close(v),
                afterclose: (v) => this.afterclose(v),
                ...payload,
            };
            let promise = new Promise((resolve, reject) => {
                newobj.reject = reject;
                newobj.resolve = resolve;
            });
            newobj.promise = promise;
            return newobj;
        },

        create(text = "", title = "", payload = {}) {
            let index = this.alerts.length;
            this.alerts.push(
                this.getPayload({
                    text,
                    title,
                    closable: true,
                    ...payload,
                })
            );
            let obj = this.alerts[this.alerts.length - 1];
            obj.alertId = "".generateId();
            obj.close = (v)=>this.close(v,obj.alertId);
            return obj;
        },
        close(resolve = true,alertId=null) {
            console.log(`Closing Alert ${alertId}`);
            let self = this;
            let objIndex = alertId==null ? this.alerts.length - 1 : this.alerts.findIndex(o=>o.alertId==alertId);
            let obj = this.alerts[objIndex];
            let inputs = this.$el.querySelectorAll("input,.input,#prompt,select,textarea");
            let values = [...inputs].reduce((o, n, i) => ({
                ...o,
                [(n.name || i)]: n.value
            }), {});
            let input = [...inputs];
            let value = values;
            if (inputs.length == 1) {
                input = inputs[0];
                value = inputs[0]?.value;
            }
            if (resolve) {
                obj?.resolve && obj?.resolve(value, values, input, obj,);
            } else {
                obj?.reject && obj?.reject(value, values, input, obj);
            }
            sys.setTimeout(() => {
                this.alerts = this.alerts.filter(o=>o.alertId!=obj.alertId);
                self.afterclose();
            }, 0);
        },
        afterclose(callback = () => ('')) {
            sys.setTimeout(() => {
                callback();
            }, 100);
        },
    },
    mounted() {
        window.Alerts = this;
        window.alert = this.create;
        window.loader = (v) =>
            this.create(
                `<span class='mx-auto'>
						<div>
							<span class='nb-spinner'></span>
						</div>
						<span>${v || ""}</span>
					</span>`,
                "",
                {
                    closable: false,
                    type: "loader",
                }
            );
        window.confirm = (v) =>
            this.create(v, "CONFIRM", {
                closable: false,
                footer: true,
                type: "confirm",
            });
        window.prompt = (v, attribute = { type: "text", placeholder: "Please enter here" }) => {
            let attributeString = attribute ? Object.attribute(attribute) : "";
            let inputHtml = '';
            if (attribute.type === 'select') {
                inputHtml = '<select id="prompt" class="input mb-5 border w-full text-gray-700 dark:text-white dark:bg-gray-800" required>';
                inputHtml += '<option value="">--Select--</option>';
                if(typeof attribute.options === 'object') {
                    for (const optionValue in attribute.options) {
                        inputHtml += `<option value="${optionValue}">${attribute.options[optionValue]}</option>`;
                    }
                }
                inputHtml += '</select>';
            } else {
                inputHtml = `<input type='${attributeString}' id='prompt' class='input mb-5 border text-gray-700 dark:text-white dark:bg-gray-800 p-2' />`;
            }

            return this.create(
                inputHtml,
                v.toTitleCase(),
                {
                    closable: false,
                    footer: true,
                    type: "prompt",
                }
            );
        };
    },
};
</script>

<style lang="scss"></style>
