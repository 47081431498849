<template>
    <div class="AppSchemaBuilder p-2 flex gap-1">
        <div class="flex flex-col gap-2">
            <button
                type="button"
                v-for="(key, j) in Object.keys(schemas)"
                :key="j"
                :class="activeTypeClass(key)"
                @click="setSchemasKey(key)"
            >
                {{ key }}
            </button>
        </div>
        <div v-if="activeType" class="grow">
            <div class="flex flex-col gap-1">
                <div class="flex gap-1">
                    <AppInput
                        v-model="activefieldKey"
                        type="select"
                        :options="schemaTabs"
                    >
                    </AppInput>
                    <div class="flex">
                        <AppButton
                            type="button"
                            class="m-auto mb-4"
                            @click="addField()"
                        >
                            Add
                        </AppButton>
                    </div>
                </div>
                <AppSchemaKeyBuilder
                    v-if="activefield"
                    class="grow"
                    v-model="activefield"
                >
                </AppSchemaKeyBuilder>
            </div>
        </div>
    </div>
</template>

<script lang="js" >
export default {
    props: {
        modelValue: {
            type: [Object],
            default: () => ({ generator: { default: [] } }),
        },
    },
    emits: ["update:modelValue"],
    data() {
        return {
            activeType: null,
            activefield: null,
            activefieldKey: null,
            schemas: this.modelValue.generator || { default: [] },
            schemaTabs: [],
        };
    },
    methods: {
        setSchemaTabs(key) {
            this.schemaTabs = Object.entries(this.schemas[key]).map(
                ([key, value]) => ({ key: value.key, value: value.key })
            );
        },
        setSchemasKey(key) {
            this.activeType = key;
            this.setSchemaTabs(key);
            this.activefieldKey = this.schemaTabs[0];
            this.setActiveField();
        },
        setActiveField() {
            this.activefield = this.schemas[this.activeType].find(
                (o) => o.key == this.activefieldKey?.key
            );
        },
        activeTypeClass(key) {
            return {
                "p-2 border dark:border-gray-800 hover:dark:bg-gray-800 cursor-pointer": true,
                "dark:bg-gray-800": key == this.activeType,
            };
        },
        sampleGenerator(fields) {
            let isArray = isNaN(fields[0]?.key) ? false : true;
            let nonNativeReducer = (type, rule, key, attributes="{}") => {
                let pattributes = getEvaled(attributes);
                pattributes.canadd = [undefined,true].includes(pattributes.canadd);
                pattributes.candelete = [undefined,true].includes(pattributes.candelete);
                pattributes.scope = pattributes.scope || [];
                pattributes.addscope = pattributes.addscope || [];
                pattributes.delscope = pattributes.delscope || [];
                pattributes.default = pattributes.default || [];
                pattributes.draggable = [undefined,true].includes(pattributes.draggable);
                pattributes.dragscope = pattributes.dragscope || [];
                pattributes.rule = rule?rule:"()=>true";

                let arr1 = [{
                    ...(rule?{rule}:{}),
                    ...({attributes:pattributes})
                }];
                return this.isArraySchema(type)
                    ? [
                          this.sampleGenerator(
                              this.getShemaObj(this.parseType(type)) || []
                          ),
                          ...arr1,
                      ]
                    : {
                          ...(rule ? { condition: { rule } } : {}),
                          ...this.sampleGenerator(
                              this.getShemaObj(this.parseType(type))
                          ),
                      };
            }
            let getNativeType = (type, attributes, rule) => {
                if (attributes || rule) {
                    return JSON.stringify({ type, attributes, rule });
                } else if (["select"].includes(type)) {
                    return JSON.stringify({ type, attributes, rule });
                } else if (["switch"].includes(type)) {
                    return false;
                } else if (["number"].includes(type)) {
                    return 0;
                } else if (["file"].includes(type)) {
                    return type + ".png";
                } else {
                    return type;
                }
            };
            if (!(fields instanceof Array)) {
                return fields;
            }
            if (typeof fields[0] !== "object") {
                return fields;
            }
            let results = fields.reduce(
                (collect, { key, type, attributes, rule }) => {
                    let output = {
                        ...collect,
                        ...{
                            [isNaN(key) ? key : +key]: this.isNonNative(type)
                                ? nonNativeReducer(type, rule, key, attributes)
                                : getNativeType(type, attributes, rule),
                        },
                    };
                    return isArray ? Object.values(output) : { ...output };
                },
                isArray ? [] : {}
            );
            return results;
        },
        attribGenerate(fields) {
            let isArray = isNaN(fields[0]?.key) ? false : true;
            let nonNativeReducer = (type, attributes, key) => {
                return this.isArraySchema(type)
                    ? [
                          this.attribGenerate(
                              this.getShemaObj(this.parseType(type)) || []
                          ),
                          getEvaled(attributes)
                      ]
                    : this.attribGenerate(
                          this.getShemaObj(this.parseType(type), attributes)
                      );
            };
            if (!(fields instanceof Array)) {
                return fields;
            }
            if (typeof fields[0] !== "object") {
                return fields;
            }
            let results = fields.reduce(
                (collect, { key, type, attributes }) => {
                    let output = {
                        ...collect,
                        ...{
                            [isNaN(key) ? key : +key]: this.isNonNative(type)
                                ? nonNativeReducer(type, attributes, key)
                                : attributes,
                        },
                    };
                    return isArray ? Object.values(output) : { ...output };
                },
                isArray ? [] : {}
            );
            return results;
        },
        ruleGenerate(fields) {
            let isArray = isNaN(fields[0]?.key) ? false : true;
            let nonNativeReducer = (type, rule, key) => {
                return this.isArraySchema(type)
                    ? [
                          this.ruleGenerate(
                              this.getShemaObj(this.parseType(type)) || []
                          ),
                          ...(rule ? [{ rule }] : [{}]),
                      ]
                    : {
                          ...this.ruleGenerate(
                              this.getShemaObj(this.parseType(type), rule)
                          ),
                          ...(rule ? { rule } : {}),
                      };
            };
            if (!(fields instanceof Array)) {
                return fields;
            }
            if (typeof fields[0] !== "object") {
                return fields;
            }
            let results = fields.reduce(
                (collect, { key, type, rule }) => {
                    let output = {
                        ...collect,
                        ...{
                            [isNaN(key) ? key : +key]: this.isNonNative(type)
                                ? nonNativeReducer(type, rule, key)
                                : rule,
                            ...(!isNaN(key) && rule ? { 1: { rule } } : []),
                        },
                    };
                    return isArray ? Object.values(output) : { ...output };
                },
                isArray ? [] : {}
            );
            return results;
        },
        shemaGenerate(fields) {
            console.log({shemaGenerate:JSON.stringify(fields)});
            let isArray = isNaN(fields[0]?.key) ? false : true;
            let nonNativeReducer = (type) =>
                this.isArraySchema(type)
                    ? [
                          this.shemaGenerate(
                              this.getShemaObj(this.parseType(type)) || []
                          ),
                      ]
                    : this.shemaGenerate(
                          this.getShemaObj(this.parseType(type))
                      );
            if (!(fields instanceof Array)) {
                return fields;
            }
            if (typeof fields[0] !== "object") {
                return fields[0];
            }
            let results = fields.reduce(
                (collect, { key, type }) => {
                    //console.log({isNonNativeReduce:key,type});
                    let output = {
                        ...collect,
                        ...{
                            [isNaN(key) ? key : +key]: this.isNonNative(type)
                                ? nonNativeReducer(type)
                                : type,
                        },
                    };
                    return isArray ? Object.values(output) : { ...output };
                },
                isArray ? [] : {}
            );
            return results;
        },
        isNonNative(type) {
            let object = this.isObjectSchema(type);
            let array = this.isArraySchema(type);
            //console.log({isNonNative:type,flag:object || array});
            return object || array;
        },
        parseType(type) {
            let derived = this.isNonNative(type);
            if (derived) {
                return derived[1];
            } else {
                return type;
            }
        },
        isArraySchema(type) {
            return type.match(/\[(.*)\]/);
        },
        isObjectSchema(type) {
            return type.match(/{(.*)}/);
        },
        getShemaObj(type = "", val = null) {
            let isValSet = val !== null;
            return this.schemas[type] || (isValSet ? val : type);
        },
        isNativeData(type) {
            return !this.isNonNative(type);
        },
        addField() {
            let fields = this.schemas[this.activeType];
            let last = fields[fields.length - 1];
            let defaultField = {
                key: "new_key_" + fields.length,
                type: "",
                rule: "",
                attributes: "",
            };
            if (
                !last ||
                !(
                    last.key == defaultField.key &&
                    last.type == defaultField.type
                )
            ) {
                fields.push(defaultField);
                this.setSchemaTabs(this.activeType);
            }
        },
        getNonNatives() {
            let nativeTypes = ["text", "number", "select", "file", "textarea"];
            return Object.entries(this.schemas)
                .map(([k, v]) => v)
                .reduce((o, n) => o.concat(n), [])
                .filter((o) =>
                    this.isNonNative(typeof o == "object" && o ? o.type : o)
                )
                .map((o) => ({
                    ...(typeof o == "object" && o ? o : {}),
                    type:
                        typeof o == "object" && o ? this.parseType(o.type) : o,
                }))
                .filter((o) => !nativeTypes.includes(o.type));
        },
        validateType() {
            let nonNatives = this.getNonNatives();
            this.schemas = nonNatives.reduce(
                (collector, { type }) => ({
                    ...collector,
                    [type]: this.schemas[type] || [],
                }),
                { default: this.schemas.default }
            );
        },
        updateModelImmediate() {
            console.log("updateModelImmediate");
            this.$emit("update:modelValue", {
                schema: this.schema,
                rules: this.rules,
                attributes: this.attributes,
                generator: this.schemas,
                //samplejson: this.samplejson,
            });
        },
        updateModel() {
            this.$nextTick(() => {
                this.debounce().then(() => {
                    this.updateModelImmediate();
                });
            });
        },
    },
    computed: {
        schema() {
            return this.shemaGenerate(this.schemas.default);
        },
        rules() {
            return this.ruleGenerate(this.schemas.default);
        },
        attributes() {
            return this.attribGenerate(this.schemas.default);
        },
        samplejson() {
            return this.sampleGenerator(this.schemas.default);
        },
    },
    watch: {
        activefieldKey(val) {
            this.setActiveField();
        },
        modelValue(val) {
            if (
                val?.generator &&
                JSON.stringify(this.schemas) != JSON.stringify(val?.generator)
            ) {
                this.schemas = val.generator;
                this.updateModelImmediate();
            }
        },
        schema(val) {
            this.updateModel();
        },
        rules(val) {
            this.updateModel();
        },
        attributes(val) {
            this.updateModel();
        },
    },
    mounted() {
        this.$el.vnode = this;
        window.AppSchemaBuilder = this;
        this.updateModel();
    },
};
</script>
