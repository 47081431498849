<template>
    <div class="w-full">
        <input type="hidden" :id="'id-' + name" :name="name" v-model="stringifiedKeyValuePairs" />
        <div class="flex flex-wrap items-center">
            <div v-for="(kvPair, idx) in getKeyValuePairsValues" class="flex gap-2 px-1">
                <AppInput
                    :label="'Key ' + (idx + 1)"
                    name=""
                    type="text"
                    v-model="kvPair[0]"
                    placeholder="Enter Key"
                    @update:modelValue="(v) => updateModelValue()"
                />
                <AppInput
                    :label="'Value ' + (idx + 1)"
                    name=""
                    type="text"
                    v-model="kvPair[1]"
                    placeholder="Enter Value"
                    @update:modelValue="(v) => updateModelValue()"
                />
                <div>
                    <i class="fa-regular fa-trash-can cursor-pointer text-red-500"
                        @click="removeKeyValue(idx)"></i>
                </div>
            </div>
            <div class="w-full md:w-1/4">
                <AppButton type="button" size="small" @click="addMoreKeyValues" class="mt-3 float-right">
                    Add more <em class="fa fa-plus"></em>
                </AppButton>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        modelValue: {},
        name: { type: String }
    },
    data() {
        return {
            default_value: {'': ''},
            _pairs: []
        };
    },
    mounted() {
        window.AppKeyValuePairs = this;
        this._pairs = Object.entries(!Object.isEmpty(this.modelValue) ? this.modelValue : this.default_value);
    },
    methods: {
        addMoreKeyValues() {
            this._pairs.push(['', '']);
        },
        removeKeyValue(index) {
            this._pairs.splice(index, 1);
            this.updateModelValue();
        },
        updateModelValue() {
            let kvPairs = this.getKeyValuePairsValues.map((itm, j) => {
                return itm.map(i => i.trim()).filter(itm => itm);
            }).filter(elm => elm.length === 2);
            let newModelVal = Object.fromEntries(kvPairs || []);
            this.$emit('update:modelValue', newModelVal);
        }
    },
    computed: {
        getKeyValuePairsValues() {
            return this._pairs;
        },
        stringifiedKeyValuePairs() {
            return JSON.stringify(this.modelValue);
        }
    }
};
</script>
  
<style scoped>
</style>