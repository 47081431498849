<template>
    <aside class="bg-gray-800 text-white sidebar min-h-screen max-h-screen overflow-auto hover:w-60 w-20  fixed top-0 left-0 ">
        <div class="p-4">
            <!-- Sidebar header -->
            <img src="https://www.setindia.com/assests/images/logo.png"
                alt="logo" class="rounded-md mb-4" />
            <h2 class="text-xl font-semibold sidebar.hover:inline-block hidden">Interactivity Admin</h2>
        </div>
        <nav>
            <!-- Menu 1 -->
            <div class="menu" v-for="item in menuPages">
                <input type="checkbox" class="hidden" :id="item.name" />
                <label :for="item.name" class="group cursor-pointer p-4 hover:bg-gray-700 items-center flex">
                    <div v-if="item.children && item.children.length" class="flex" >
                        <i :class="item.icon"></i>
                        <span class="sidebar.hover:inline-block hidden ml-2">{{ item.label }}</span>
                    </div>
                    <router-link :to="item.path" class="flex grow" v-else >
                        <i :class="item.icon"></i>
                        <span class="sidebar.hover:inline-block hidden ml-2">{{ item.label }}</span>
                    </router-link>
                    <template v-if="item.children && item.children.length">
                        <i class="fas fa-chevron-down ml-auto "></i>
                        <i class="fas fa-chevron-left ml-auto "></i>
                    </template>
                </label>
                <template v-if="item.children && item.children.length" >
                    <ul class="sub-menu" v-for="citem in item.children" >
                        <li class="group cursor-pointer p-2 pl-6 hover:bg-gray-700">
                            <i :class="item.icon"></i>
                            <span class="menu-item-text ml-2">{{citem.label}}</span>
                        </li>
                    </ul>
                </template>
            </div>
            <div v-if="uiPages.length" class="px-4 py-2 sidebar.hover:inline-block hidden text-lg mt-4">Pages</div>
            <hr/>
            <template v-if="uiPages.length">
                <div class="menu" v-for="item in uiPages">
                    <input type="checkbox" class="hidden" :id="item.name" />
                    <label :for="item.name" class="group cursor-pointer p-4 hover:bg-gray-700 items-center flex">
                        <a :href="item.path" class="flex grow" >
                            <i class="fa fa-list-check"></i>
                            <span class="sidebar.hover:inline-block hidden ml-2">{{ item.name.toTitleCase() }}</span>
                        </a>
                    </label>
                </div>
            </template>
            <div v-if="curdTables.length" class="px-4 py-2 sidebar.hover:inline-block hidden text-lg mt-4 font-bold">CURD Pages</div>
            <hr/>
            <template v-if="curdTables.length">
                <div class="menu" v-for="item in curdTables">
                    <input type="checkbox" class="hidden" :id="item.name" />
                    <label :for="item.name" class="group cursor-pointer p-4 hover:bg-gray-700 items-center flex">
                        <a :href="item.path" class="flex grow" >
                            <i class="fa fa-list-check"></i>
                            <span class="sidebar.hover:inline-block hidden ml-2">{{ item.name.toTitleCase() }}</span>
                        </a>
                    </label>
                </div>
            </template>
        </nav>
    </aside>
</template>
  
<script>
import sidenavmenu from "./sidenavmenu.js";
export default {
    data() {
        return {
            menuItems: sidenavmenu,
            tableList: []
        }
    },
    computed:{
        menuPages() {
            return this.menuItems.filter(o=>{
                let page = this.tableList.filter(o=>o.hasui).find(v=>v.route==o.path);
                return page?true:false; 
            })
        },
        curdTables() {
            return this.tableList.filter(o=>!o.hasui).map(o=>{
                let queryString = Object.QueryString({
                    modelname:o.hasmodal?o.modelName:undefined,
                    tablename:!o.hasmodal?o.name:undefined
                });
                return {
                    ...o,
                    queryString,
                    path: `/admin/datatable?${queryString}`
                }
            });//.filter(o=>!["files"].includes(o.name))
        },
        uiPages() {
            return this.tableList.filter(o=>o.hasui).map(o=>{
                return {
                    ...o,
                    name: o.route.filename(),
                    path: o.route
                }
            }).filter(o=>!this.menuItems.map(v=>v.path).includes(o.path))
        }
    },
    methods: {
        getTableList() {
            this.apiCurd("Dynamic",{action:"tablelist"}).then(({data})=>{
                this.tableList = data;
            });
        }
    },
    mounted() {
        this.getTableList();
        window.Sidebar = this;
    },
};
</script>
  
<style scoped>
.sidebar {
    transition: width 0.2s;
}

.menu .sub-menu {
    transition: all 1s;
    display: none;
}

.menu input:checked+.items-center+.sub-menu {
    display: block;
}

.menu input+.items-center i.fa-chevron-left {
    display: none;
}

.menu input:checked+.items-center i.fa-chevron-left {
    display: initial;
}

.menu input:checked+.items-center i.fa-chevron-down {
    display: none;
}

.sidebar .menu-item-text {
    display: none;
}

.sidebar:hover .menu-item-text {
    display: inline-block;
}

/* Add other styles as needed */
</style>
  