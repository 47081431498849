<template>
    <div class="flex flex-wrap gap-1" v-if="modelValue">
        <AppInput pclass="grow" v-model="modelValue.key" type="text" label="Name" />
        <AppInput
            pclass="grow"
            v-model="modelValue.type"
            type="text"
            label="Type"
            @update:modelValue="validateType"
        />
    </div>
    <div class="grow">
        <AppTab :tabs="mytabs" v-model="tab" type="content"></AppTab>
        <div class="flex">
            <template v-if="tabs.map(v=>v.key).includes(tab.key)" >
                <AppInput
                    pclass="grow w-0"
                    v-model="modelValue[tab.key]"
                    type="code"
                    :codeType="tab.key=='rule'?'javascript':'json'"
                    :label="tab.value"
                />
            </template>
            <template v-else>
                <AppInput
                    pclass="grow w-0"
                    v-model="scripts[tab.key]"
                    @update:modelValue="(v)=>onScriptChange(v,tab.key)"
                    type="code"
                    codeType="javascript"
                    :label="tab.value"
                />
            </template>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        modelValue: {
            type:Object,
            default:()=>({})
        },
    },
    data() {
        return {
            tab: {key:"rule",value:"Rule"},
            tabs:[
                {key:"rule",value:"Rule"},
                {key:"attributes",value:"Attributes"}
            ],
            attributes:null,
            scripts:{}
        }
    },
    computed:{
        scriptKeys() {
            return Object.keys(this.modelValue.attributes.parse()).filter(key=>key.isEvent());
        },
        mytabs() {
            return [
                ...this.tabs,
                ...this.scriptKeys.map(key=>({
                    key,value:key
                }))
            ]
        }
    },
    methods:{
        onScriptChange(value,key) {
            this.attributes[key] = value;
            this.modelValue.attributes = JSON.stringify(this.attributes).jsonPretty();
            console.log("onScriptChange");
        },
        validateType() {
            this.$emit("validatetype");
        }
    },
    watch:{
        tab:{
            immediate:true,
            handler(value) {
                this.attributes = this.modelValue.attributes.parse();
                this.scripts = this.scriptKeys.reduce((old,key)=>({...old,[key]:this.attributes[key]}),{});
            }
        }
        
    },
    mounted() {
        window.AppSchemaKeyBuilder = this;
    }
};
</script>

<style></style>
