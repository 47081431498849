<template >
    <div class="AppDragList" :draggable="draggable" v-for="(obj, i) in (list || [])" :key="i" @dragstart="dragStart(i)" @dragover="dragOver" @drop="drop($event,i)">
        <slot :obj="obj" :i="i">
        </slot>
    </div>
</template>
<script>
export default {
    props: {
        list: {
            type: Array,
            default: () => ([])
        },
        draggable: {
            type: Boolean,
            default: true
        }
    },
    methods: {
        dragStart(index) {
            //console.log({dragStart:index});
            event.dataTransfer.setData("text/plain", index);
        },
        dragOver(event) {
            // Allow the element to be dropped
            event.preventDefault();
        },
        drop(event,dropIndex) {
            const oldIndex = event.dataTransfer.getData("text/plain");
            const newlist = [...this.list];
            const draggedItem = newlist.splice(oldIndex, 1)[0];
            newlist.splice(dropIndex, 0, draggedItem);
            //console.log({event,dropIndex,draggedItem,newlist,list:this.list});
            this.$emit("change-list",newlist);
        }
    }
}
</script>
<style lang="scss"></style>