<template>
    <div class="app-input mb-4">
        <label
            class="block text-gray-700 dark:text-white text-sm font-bold mb-2"
            :for="'id-' + name"
        >
            {{ label }}
        </label>
        <template v-if="['select'].includes(type)">
            <select
                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 dark:text-white dark:bg-gray-800 leading-tight focus:outline-none focus:shadow-outline"
                :id="'id-' + name"
                :name="name"
                @input="handleInputChange"
                v-bind="Object.except($attrs, ['type', 'options'])"
            >
                <option
                    v-for="option in options"
                    :key="option.value"
                    :value="option.value"
                    :selected="isSelected(option)"
                >
                    {{ option.label }}
                </option>
            </select>
        </template>
        <template v-else-if="['search'].includes(type)">
            <AppAutocomplete
                ref="appautocomplete"
                :name="name" 
                :modelValue="modelValue"
                @update:modelValue="(...args)=>$emit('update:modelValue',...args)"
                v-bind="$attrs" 
            />
        </template>
        <template v-else-if="['switch'].includes(type)">
            <AppSwitch 
                ref="appswitch"
                :name="name"
                :modelValue="!!modelValue"
                @update:modelValue="(value) => $emit('update:modelValue', value)"
                v-bind="$attrs"
                :class="{ disabled:$attrs.disabled, 'text-black': true }"
            />
        </template>
        <template v-else-if="['file'].includes(type)">
            <AppFileUploader 
                ref="appfileuploader"
                :name="name"
                :modelValue="modelValue" 
                @update:modelValue="(value) => $emit('update:modelValue', value)" 
                :type="type"
                v-bind="$attrs"
                >
            </AppFileUploader>
        </template>
        <template v-else-if="['markdown'].includes(type)">
            <!-- textarea markdown -->
            <AppMarkdown
                :name="name"
                :modelValue="modelValue" 
                @update:modelValue="(value) => $emit('update:modelValue', value)"
                v-bind="$attrs"
                >
            </AppMarkdown>
        </template>
        <template v-else-if="['textarea'].includes(type)">
            <textarea
                class="w-full dark:text-white dark:bg-gray-800"
                :name="name"
                :value="modelValue" 
                @input="(event) => $emit('update:modelValue', event.target.value)"
                v-bind="$attrs"
                >
            </textarea>
        </template>
        <template v-else-if="['code'].includes(type)" >
            <AppCodeEditor
                ref="appcodeeditor"
                :name="name"
                :modelValue="modelValue"
                :inputHeight="inputHeight"
                @update:modelValue="(value) => $emit('update:modelValue', value)" 
                :type="type"
                v-bind="$attrs"
                >
            </AppCodeEditor>
        </template>
        <template v-else-if="['color'].includes(type)" >
            <input
                :value="modelValue"
                class="min-w-[7rem] shadow appearance-none border rounded w-full py-1 h-12 px-3 text-gray-700 dark:text-white dark:bg-gray-800 leading-tight focus:outline-none focus:shadow-outline"
                :id="'id-' + name"
                :name="name"
                :type="type"
                @input="handleInputChange"
                v-bind="$attrs"
            />
        </template>
        <template v-else-if="['datetime-local'].includes(type)" >
            <input
                :value="modelValue"
                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 dark:text-white dark:bg-gray-800 leading-tight focus:outline-none focus:shadow-outline"
                step="any"
                :id="'id-' + name"
                :name="name"
                :type="type"
                :required="required"
                :placeholder="placeholder"
                @input="handleInputChange"
                v-bind="$attrs"
            />
        </template>
        <template v-else-if="['key_value_pairs'].includes(type)" >
            <AppKeyValuePairs
                :name="name" 
                :modelValue="modelValue"
                @update:modelValue="(...args)=>$emit('update:modelValue',...args)"
                v-bind="$attrs" 
            />
        </template>
        <input
            v-else
            :value="modelValue"
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 dark:text-white dark:bg-gray-800 leading-tight focus:outline-none focus:shadow-outline"
            :id="'id-' + name"
            :name="name"
            :type="type"
            :required="required"
            :placeholder="placeholder"
            @input="handleInputChange"
            v-bind="$attrs"
        />
        <small v-if="description" class="text-gray-300">{{ description }}</small>
    </div>
</template>
<script>
import AppMarkdown from '../../components/AppMarkdown.vue';

export default {
    components: {
        AppMarkdown
    },
    inheritAttrs: false,
    props: {
        label: { type: String, default: "Label" },
        name: { type: String, default: "name" },
        modelValue: { type: [Array,String,Number, Object], default: "" },
        type: { type: String, default: "text" },
        description: { type: String, default: "" },
        required: { type: Boolean, default: false },
        placeholder: { type: String, default: "" },
        inputHeight: { type: [String, Number], default: null }
    },
    mounted() {
        window.AppInput = this;
        this.$el.vnode = this;
        console.log('AppIp props : ' + this.name, this.description, this.modelValue, this);
    },
    methods: {
        log:console.log,
        isSelected(option) {
            if(this.modelValue instanceof Array) {
                return this.modelValue && this.modelValue instanceof Array && this.modelValue.includes(option.value);
            } else {
                return this.modelValue == option.value;
            }
            
        },
        handleInputChange($event) {
            this.debounce().then(()=>{
                let ipVal = $event.target.value;
                if ($event.target.multiple) {
                    ipVal = [...$event.target.selectedOptions].map(o => o._value);
                }
                this.$emit("update:modelValue", ipVal);
            });
        },
    },
    computed: {
        options() {
            console.log("AppInput",this.$attrs.options);
            let hasRequiredColumns = (this.$attrs.options||[]).has(['value','label']);
            let hasKeyValue = (this.$attrs.options||[]).has(['key','value']);
            if(!hasRequiredColumns && hasKeyValue) {
                return (this.$attrs.options||[]).options('key','value');
            } else {
                return (this.$attrs.options||[]);
            }
        }
    }
};
</script>
<style>
    .app-input {
        width:100%;
    }
</style>
