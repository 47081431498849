<template lang="">
    <div :class="{'app-layout ':true,'dark': $state.darkmode }">
        <slot></slot>
        <AppAlert/>
    </div>
</template>
<script>
export default {
    watch:{
        "loading":(val)=>{

        }
    }
}
</script>
<style lang="">
    
</style>