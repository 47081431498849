<template>
    <AppLayout>
        <component :is="layoutName"></component>
    </AppLayout>
</template>

<script>
import AppLayout from "./AppLayout.vue";
export default {
    components: {
        AppLayout
    },
    data() {
        return {

        }
    },
    mounted() {
        window.app = this;
    },
    computed: {
        layoutName() {
            return this.layout.toTitleCase() + 'Layout';
        }
    },
    watch: {
        "$state.loggedoutAt": function (value, old) {
            console.log({ value, old, layout: this.layout }, this.currentComponent);
        }
    }
}
</script>
<style lang="scss">

</style>
