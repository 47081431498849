<template>
    <div :class="{ 'flex h-screen overflow-hidden dark:bg-gray-900 dark:text-white': true}">
        <Sidebar />
        <Navbar />
        <div class="content ml-20 h-screen mt-14 p-4 w-full">
            <router-view></router-view>
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    components: {

    },
    mounted() {
        window.EmployerLayout = this;
        console.log('Component mounted.')
    }
}
</script>
<style lang="scss">
.content {
    transition: margin-left 0.2s;
}
.sidebar:hover+.navbar {
    margin-left: 15rem;
}
.sidebar:hover+.navbar+.content {
    margin-left: 15rem;
}
</style>
