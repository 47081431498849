Array.prototype.sum = function (ini = 0) {
    return this.reduce((a, b) => (Number(a) + Number(b)), ini).toFixed(2).parse();
}

Array.prototype.col = function (col, value) {
    return this.find(o => o[col] == value);
}

Array.prototype.obj = function (col = "name", val = "value") {
    return this.reduce((o, n) => ({ ...o, [n[col]]: n[val] }), {});
}

Array.prototype.maxObj = function () {
    if (this.length) {
        let initObj = this[0];
        let len = Object.keys(initObj);
        for (const key in this) {
            let newlen = Object.keys(this[key]).length;
            if (len < newlen) {
                len = newlen;
                initObj = this[key]
            }
        }
        return initObj;
    }
}

Array.prototype.maxObjKeys = function () {
    return Object.keys(this.maxObj() || {});
}

Array.prototype.csv = function () {
    let csv = "";
    let headers = this.maxObjKeys().map(o => `"${o}"`).join() + "\n"
    let data = this.map(o => {
        return Object.values(o).map(o => `"${o}"`).join();
    }).join("\n");
    csv = headers + data;
    return csv;
}

Array.prototype.unique = function () {
    return Array.from((new Set(this)));
}

Array.prototype.chunk = function (perChunk) {
    return this.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / perChunk)
        if (!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = []
        }
        resultArray[chunkIndex].push(item)
        return resultArray
    }, []);
}

Array.prototype.filllen = function(len,val=0) {
    let arr = [];
    arr.length = len;
    len && arr.fill(val);
    return (len && arr) || [];
}

Array.prototype.options = function(key="id",value="name",keyname="value",labelname="label") {
    return this.map(o => (
        (o && typeof o === "object") ?
            { ...o, [keyname]: o[key], [labelname]: o[value] } :
            { [keyname]: o, [labelname]: o.toTitleCase() }
    ));
}

Array.prototype.first = function() {
    return this[0];
}

Array.prototype.toggel = function(value) {
    let hasval = this.includes(value);
    if(hasval) {
        const index = this.indexOf(value);
        this.splice(index, 1);
    };
    !hasval && (this.push(value));
}

Array.prototype.group = function(col="group") {
    return this.reduce((o,n)=>({...o,[n[col]||'default']:this.filter(obj=>obj[col]==n[col])}),{});
}

Array.prototype.sortBySubstr = function (colname = "") {
    return this.sort((a, b) => {
        const aValue = typeof a == "object" ? a[colname] : a;
        const bValue = typeof a == "object" ? a[colname] : a;
        const aSubstrings = aValue.split(/[_\s]/);
        const bSubstrings = bValue.split(/[_\s]/);
        const aSubstringsLength = aSubstrings.length;
        const bSubstringsLength = bSubstrings.length;

        for (let i = 0; i < Math.min(aSubstringsLength, bSubstringsLength); i++) {
            if (aSubstrings[i] !== bSubstrings[i]) {
                return aSubstrings[i].localeCompare(bSubstrings[i]);
            }
        }

        return aSubstringsLength - bSubstringsLength;
    });
}



Array.prototype.has = function(required=[]) {
    return this.map(o=> Object.has(o,required)).reduce((f,c)=>(f&&c),true);
};