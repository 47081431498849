<template lang="">
    <div
        v-show="loading"
        class="fixed left-0 top-0 z-999999 flex h-screen w-screen items-center justify-center bg-white"
        style="display: none"
    >
        <div
            class="h-16 w-16 animate-spin rounded-full border-4 border-solid border-primary border-t-transparent"
        ></div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            loading: true
        }
    },
    mounted() {
        setTimeout(()=>{
            this.loading = false
        },1000);
    }
};
</script>
<style lang="scss"></style>
